import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { JourneyType } from "@/models/JourneyType";
import { JourneyTimeType } from "@/models/JourneyTimeType";
import { ViaAvoidType } from "@/models/ViaAvoidType";
import type { JourneyQuery } from "@/models/JourneyQuery";
import type { GetJourneysRequest } from "@/models/remote/GetJourneysRequest";
import { useApolloClient } from '@vue/apollo-composable';
import _get from 'lodash/get';
import GetPlaceById from "@/graphql/queries/GetPlaceById.graphql";
import { getRailcards } from "@/graphql/functions/getRailcards";
import type { FaresValueModel } from "@/models/basket/FaresValueModel";
import { getJourneySearch } from "@/graphql/functions/getJourneySearch";
import { getJourneySearchById } from "@/graphql/functions/getJourneySearchById";
type ResultsDirection = "earlier" | "later";
type SelectedJourney = {
    journeyKey: string;
    fareClass: 'standard' | 'first';
};

export const useJourneyStore = defineStore("journey", () => {
    // Initialise the date to now, but round up to the nearest 15 minute interval
    const interval = 15 * 60 * 1000;
    const today = new Date(Math.ceil(new Date().getTime() / interval) * interval);
    const tomorrow = new Date(Math.ceil(new Date().getTime() / interval) * interval);
    tomorrow.setDate(today.getDate() + 1);

    const query: JourneyQuery = {
        adults: ref(1),
        children: ref(0),
        originStation: ref(undefined),
        destinationStation: ref(undefined),
        outboundDate: ref(today),
        outboundTimeType: ref(JourneyTimeType.DepartingAt),
        returnDate: ref(tomorrow),
        returnTimeType: ref(JourneyTimeType.DepartingAt),
        railCard: ref(undefined),
        railCardQuantity: ref(1),
        journeyType: ref(JourneyType.OneWay),
        viaAvoidStation: ref(undefined),
        viaAvoid: ref(ViaAvoidType.Via),
    };

    const journeySearchResults = ref([{ outbound: {} }]);

    // Used to update the query store object with the query values from the URL request. This is needed when the app is loaded straight to this step directly, meaning the store is empty, so the journey planner fields are empty.
    async function updateQueryFromRequest(request: GetJourneysRequest) {
        const { client } = useApolloClient()
        query.journeyType.value = request.journeyType;

        // Convert the ID of the stations into the full station object
        try {
            const result = await client.query({
                query: GetPlaceById,
                variables: {
                    id: request.from
                }
            });

            query.originStation.value = result.data.placeById;

            const destinationResult = await client.query({
                query: GetPlaceById,
                variables: {
                    id: request.to
                }
            });

            query.destinationStation.value = destinationResult.data.placeById;
        } catch (error) {
            console.error(error);
        }

        query.adults.value = parseInt(request.adults.toString(), 10);
        query.children.value = parseInt(request.children.toString(), 10);

        if (request.railcard) {
            query.railCardQuantity.value = request.railcardQuantity;

            try {
                const railcards = await getRailcards();
                if (railcards) {
                    query.railCard.value = railcards.find((x: { code: string }) => x.code === request.railcard);
                }
            } catch (error) {
                console.error(error);
            }
        }

        query.outboundDate.value = new Date(request.outboundDate);
        query.outboundTimeType.value = request.outboundTimeType;

        if (request.returnDate) {
            query.returnDate.value = new Date(request.returnDate);
        }

        if (request.returnTimeType) {
            query.returnTimeType.value = request.returnTimeType;
        }

        if (request.viaAvoid) {
            query.viaAvoid.value = request.viaAvoid;
        }

        if (request.viaAvoidStation) {
            try {
                const viaAvoidResult = await client.query({
                    query: GetPlaceById,
                    variables: {
                        id: request.viaAvoidStation
                    }
                });

                query.viaAvoidStation.value = viaAvoidResult.data.placeById;
            } catch (error) {
                console.error(error);
            }
        }
    }

    const journeyQuery = computed(() => query);
    const hasRailCard = computed(() => query.railCard.value?.code !== undefined);
    const hasViaAvoidStation = computed(() => query.viaAvoidStation.value?.id !== undefined);

    // Returns an array of all selectedOutboundFares and selectedInboundFares.
    function getArrayOfAllFares() {
        return [...selectedOutboundFares.value, ...selectedInboundFares.value];
    }

    const currentOutboundJourneySearchResultsPageNumber = ref(0);
    const currentInboundJourneySearchResultsPageNumber = ref(0);
    const journeySearchResultsPerPage = ref(5);
    const isJourneySearchApiLoading = ref(false);
    const selectedOutboundJourney = ref<SelectedJourney>({} as SelectedJourney);
    const selectedInboundJourney = ref<SelectedJourney>({} as SelectedJourney);
    const selectedOutboundFares = ref<string[]>([]);
    const selectedInboundFares = ref<string[]>([]);
    const getPricingBreakdownError = ref<string>("");

    function resetSelectedJourneysAndFares() {
        selectedOutboundJourney.value = {} as SelectedJourney;
        selectedInboundJourney.value = {} as SelectedJourney;
        selectedOutboundFares.value = [];
        selectedInboundFares.value = [];
    }

    // Preselects the cheapest outbound journey and inbound journey (if present). This is used to preselect the first journey in the list when the user first searches for journeys.
    function preselectJourneys() {
        function selectDefaultOutboundJourney() {
            // To preselect the cheapest journey, loop through all journeys and select the first cheapest one we find.
            for (const journey of journeySearchResults.value.outbound.journeys) {
                if (isJourneyAndClassTheCheapest(journey.key, "standard", "outbound")) {
                    selectedOutboundJourney.value = {
                        journeyKey: journey.key,
                        fareClass: "standard"
                    };
                    break
                } else if (isJourneyAndClassTheCheapest(journey.key, "first", "outbound")) {
                    selectedOutboundJourney.value = {
                        journeyKey: journey.key,
                        fareClass: "first"
                    };
                    break
                }
            }

            // If no cheapest journey was selected, which may happen if a return ticket is cheaper, then preselect the first journey in the list.
            if (!selectedOutboundJourney.value.journeyKey) {
                selectedOutboundJourney.value = {
                    journeyKey: journeySearchResults.value.outbound.journeys[0].key,
                    fareClass: "standard"
                };
            };

        };

        function selectDefaultInboundJourney() {
            for (const journey of journeySearchResults.value.inbound.journeys) {
                if (isJourneyAndClassTheCheapest(journey.key, "standard", "inbound")) {
                    selectedInboundJourney.value = {
                        journeyKey: journey.key,
                        fareClass: "standard"
                    };
                    break
                } else if (isJourneyAndClassTheCheapest(journey.key, "first", "inbound")) {
                    selectedInboundJourney.value = {
                        journeyKey: journey.key,
                        fareClass: "first"
                    };
                    break
                }
            }

            // If no cheapest journey was selected, which may happen if a return ticket is cheaper, then preselect the first journey in the list.
            if (!selectedInboundJourney.value.journeyKey) {
                selectedInboundJourney.value = {
                    journeyKey: journeySearchResults.value.inbound.journeys[0].key,
                    fareClass: "standard"
                };
            };
        };

        // If the currently selected JourneyKey doesn't exist anymore, pre-select a new one. If it does, then leave it as that must mean the other side has been paginated.
        if (selectedOutboundJourney.value.journeyKey) {
            let found = journeySearchResults.value?.outbound.journeys.find((journey) => journey.key === selectedOutboundJourney.value.journeyKey);
            if (found === undefined) {
                selectDefaultOutboundJourney();
            }
        } else {
            // No journey is selected, so preselect the first journey in the list.
            selectDefaultOutboundJourney();
        }

        // Only run any of this code if there are inbound journeys present.
        if (journeySearchResults.value.inbound?.journeys?.length > 0) {
            if (selectedInboundJourney.value.journeyKey) {
                let found = journeySearchResults.value?.inbound.journeys.find((journey) => journey.key === selectedInboundJourney.value.journeyKey);
                if (found === undefined) {
                    selectDefaultInboundJourney();
                }
            } else {
                // No journey is selected, so preselect the first journey in the list.
                selectDefaultInboundJourney();
            }
        }

        // Set the selected fares to the cheapest fares for the selected journeys.
        chooseAndStoreFaresDependingOnCurrentJourneySelection();
    }

    function fetchJourneysFromGqlApi(searchQuery: GetJourneysRequest) {
        return new Promise(async (resolve, reject) => {
            try {
                isJourneySearchApiLoading.value = true

                resetSelectedJourneysAndFares();

                let searchInput = {
                    adults: searchQuery.adults,
                    children: searchQuery.children,
                    destination: searchQuery.to,
                    origin: searchQuery.from,
                    returnOpen: false,
                    takeItems: journeySearchResultsPerPage.value,
                }

                // Add any railcards
                if (searchQuery.railcardQuantity > 0) {
                    searchInput.railcards = [
                        {
                            code: searchQuery.railcard,
                            count: searchQuery.railcardQuantity
                        }
                    ];
                };

                // Add the outbound departing or arriving datetime
                if (searchQuery.outboundTimeType === JourneyTimeType.DepartingAt) {
                    searchInput.departing = searchQuery.outboundDate;
                } else {
                    searchInput.arriving = searchQuery.outboundDate;
                }

                // Add the return datetime if it's a return journey
                if (searchQuery.journeyType === JourneyType.Return) {
                    if (searchQuery.returnTimeType === JourneyTimeType.DepartingAt) {
                        searchInput.returnDeparting = searchQuery.returnDate;
                    } else {
                        searchInput.returnArriving = searchQuery.returnDate;
                    }
                }

                // Add Open Return flag if it's an Open Return journey
                if (searchQuery.journeyType === JourneyType.OpenReturn) {
                    searchInput.returnOpen = true;
                }

                if ((searchQuery.viaAvoid === ViaAvoidType.Via) && (searchQuery.viaAvoidStation)) {
                    searchInput.via = {
                        direction: "OUTBOUND",
                        placeId: searchQuery.viaAvoidStation,
                        type: "PASS"
                    };
                } else if ((searchQuery.viaAvoid === ViaAvoidType.Avoid) && (searchQuery.viaAvoidStation)) {
                    searchInput.avoid = {
                        direction: "OUTBOUND",
                        placeId: searchQuery.viaAvoidStation,
                        type: "PASS"
                    };
                }

                let result = await getJourneySearch(searchInput);

                journeySearchResults.value = result
                isJourneySearchApiLoading.value = false

                preselectJourneys()

                resolve(null)
                return
            } catch (error) {
                console.error(error)
                isJourneySearchApiLoading.value = false
                reject()
                return
            }
        })

    }

    // Calls journeySearchById query which allows us to get the next or previous page of results from the original server-side cached journeySearch query performed in fetchJourneysFromGqlApi(). Uses the searchId of the previous search as the identifier.
    // @param direction - 'earlier' or 'later' to determine which page of results to fetch
    // @param type - 'outbound' or 'inbound' to determine which journey type to paginate for.
    function paginateJourneysFromGqlApi(direction: ResultsDirection, type: "outbound" | "inbound") {
        return new Promise(async (resolve, reject) => {
            try {
                isJourneySearchApiLoading.value = true;
                let inboundSkip = currentInboundJourneySearchResultsPageNumber.value;
                let outboundSkip = currentOutboundJourneySearchResultsPageNumber.value;

                if (type === "outbound") {
                    outboundSkip = currentOutboundJourneySearchResultsPageNumber.value + journeySearchResultsPerPage.value;
                } else if (type === "inbound") {
                    inboundSkip = currentInboundJourneySearchResultsPageNumber.value + journeySearchResultsPerPage.value;
                }

                if (direction === "earlier") {
                    if (type === "outbound") {
                        outboundSkip = currentOutboundJourneySearchResultsPageNumber.value - journeySearchResultsPerPage.value;
                    } else {
                        inboundSkip = currentInboundJourneySearchResultsPageNumber.value - journeySearchResultsPerPage.value;
                    }
                }

                let searchInput = {
                    searchId: journeySearchResults.value.searchId,
                    outboundPagination: {
                        journeySort: "DEPARTURE_ASCENDING",
                        skip: outboundSkip,
                        take: journeySearchResultsPerPage.value
                    },
                    inboundPagination: {
                        journeySort: "DEPARTURE_ASCENDING",
                        skip: inboundSkip,
                        take: journeySearchResultsPerPage.value
                    }
                }

                let result = await getJourneySearchById(searchInput);

                journeySearchResults.value = result;

                if (direction === "earlier") {
                    if (type === "outbound") {
                        currentOutboundJourneySearchResultsPageNumber.value -= journeySearchResultsPerPage.value;
                    } else {
                        currentInboundJourneySearchResultsPageNumber.value -= journeySearchResultsPerPage.value;
                    }
                } else {
                    if (type === "outbound") {
                        currentOutboundJourneySearchResultsPageNumber.value += journeySearchResultsPerPage.value;
                    } else {
                        currentInboundJourneySearchResultsPageNumber.value += journeySearchResultsPerPage.value;
                    }
                }
                isJourneySearchApiLoading.value = false;

                preselectJourneys();

                resolve(null);
                return;
            } catch (error) {
                console.error(error)
                isJourneySearchApiLoading.value = false;
                reject();
                return;
            }
        })
    }

    // Searches the journeySearchResults object for a journey with the provided journeyKey, and returns the journey object if found. Works for both inbound and outbound. If not found, returns null.
    function getJourneyByJourneyKey(journeyKey: string) {
        let found = journeySearchResults.value?.outbound?.journeys.find((journey) => journey.key === journeyKey);
        if (found === undefined) {
            // If not found in outbound, check inbound
            found = journeySearchResults.value?.inbound?.journeys.find((journey) => journey.key === journeyKey);
        }
        if (found === undefined) {
            return null;
        } else {
            return found;
        }
    }

    // Used for getting the correct fare from the 'fares' array field from the journeySearchResults object, by the fare key.
    function getFareObjectByFareKey(fareKey: string) {
        if (_get(journeySearchResults.value, 'fares', false) === false) return null;
        return journeySearchResults.value?.fares.find((fare) => fare.key === fareKey);
    }

    // Get a JourneyFare object that matches the outbound journeyKey provided.
    function getJourneyFareForOutboundJourneyKey(journeyKey: string) {
        if (_get(journeySearchResults.value, 'journeyFares', false) === false) return null;
        return journeySearchResults.value?.journeyFares.find((journeyFare) => journeyFare.journeyOutKey === journeyKey);
    }

    // Get a JourneyFare object that matches the inbound journeyKey provided.
    function getJourneyFareForInboundJourneyKey(journeyKey: string) {
        if (_get(journeySearchResults.value, 'journeyFares', false) === false) return null;
        return journeySearchResults.value?.journeyFares.find((journeyFare) => journeyFare.journeyInKey === journeyKey);
    }

    // Find the JourneyFare object that matches the outbound and inbound journeyKeys provided, and return it. Only for Return journeys.
    function getJourneyFareForReturnJourneyKeys(outboundJourneyKey: string, inboundJourneyKey: string) {
        if (_get(journeySearchResults.value, 'journeyFares', false) === false) return null;
        return journeySearchResults.value?.journeyFares.find((journeyFare) => journeyFare.journeyOutKey === outboundJourneyKey && journeyFare.journeyInKey === inboundJourneyKey);
    }

    // When provided with a outbound JourneyKey, return the cheapest standard class Fare object. Works for both Single and Open Return journeys.
    // @return - Fare object, or null if no standard class fares are available.
    function getCheapestStandardClassFareForOutbound(journeyKey: string): FaresValueModel | null {
        let journeyFare = getJourneyFareForOutboundJourneyKey(journeyKey);
        if (!journeyFare) return null;

        let fareObject = undefined;
        if (query.journeyType.value === JourneyType.OpenReturn) {
            fareObject = getFareObjectByFareKey(journeyFare.standardClass.returnFares[0]);
        } else {
            fareObject = getFareObjectByFareKey(journeyFare.standardClass.singleOutFares[0]);
        }
        if (fareObject === undefined) return null;
        return fareObject.value;
    }

    // As above, but just return the priceFormatted field of the Fare object.
    // @return - Price formatted string, or null if no standard class fares are available.
    function getCheapestStandardClassFarePriceForOutbound(journeyKey: string): string | null {
        let fare = getCheapestStandardClassFareForOutbound(journeyKey);
        if ((fare === undefined) || (fare === null)) {
            return null;
        } else {
            return fare.priceFormatted;
        }
    }

    // When provided with an inbound JourneyKey, return the cheapest standard class Fare object. Note we don't need to worry about Open Returns here as they don't have inbound fares.
    function getCheapestStandardClassFareForInbound(journeyKey: string): FaresValueModel | null {
        let journeyFare = getJourneyFareForInboundJourneyKey(journeyKey);
        if (!journeyFare) return null;
        let fareObject = getFareObjectByFareKey(journeyFare.standardClass.singleInFares[0]);
        if (fareObject === undefined) return null;
        return fareObject.value;
    }

    // As above, but just return the priceFormatted field of the Fare object.
    // @return - Price formatted string, or null if no standard class fares are available.
    function getCheapestStandardClassFarePriceForInbound(journeyKey: string): string | null {
        let fare = getCheapestStandardClassFareForInbound(journeyKey);
        if ((fare === undefined) || (fare === null)) {
            return null;
        } else {
            return fare.priceFormatted;
        }
    }

    // When provided with a outbound JourneyKey, return the cheapest first class Fare object. Works for both Single and Open Return journeys.
    // @return - Fare object, or null if no first class fares are available.
    function getCheapestFirstClassFareForOutbound(journeyKey: string): FaresValueModel | null {
        let journeyFare = getJourneyFareForOutboundJourneyKey(journeyKey);
        if (!journeyFare) return null;

        let fareObject = undefined;
        if (query.journeyType.value === JourneyType.OpenReturn) {
            fareObject = getFareObjectByFareKey(journeyFare.firstClass.returnFares[0]);
        } else {
            fareObject = getFareObjectByFareKey(journeyFare.firstClass.singleOutFares[0]);
        }
        if (fareObject === undefined) return null;
        return fareObject.value;
    }

    // As above, but just return the priceFormatted field of the Fare object.
    // @return - Price formatted string, or null if no first class fares are available.
    function getCheapestFirstClassFarePriceForOutbound(journeyKey: string): string | null {
        let fare = getCheapestFirstClassFareForOutbound(journeyKey);
        if ((fare === undefined) || (fare === null)) {
            return null;
        } else {
            return fare.priceFormatted;
        }
    }

    // When provided with a inbound JourneyKey, return the cheapest first class Fare object.
    // @return - Fare object, or null if no first class fares are available.
    function getCheapestFirstClassFareForInbound(journeyKey: string): FaresValueModel | null {
        let journeyFare = getJourneyFareForInboundJourneyKey(journeyKey)
        if (!journeyFare) return null;
        let fareObject = getFareObjectByFareKey(journeyFare.firstClass.singleInFares[0]);
        if (fareObject === undefined) return null;
        return fareObject.value;
    }

    // As above, but just return the priceFormatted field of the Fare object.
    // @return - Price formatted string, or null if no first class fares are available.
    function getCheapestFirstClassFarePriceForInbound(journeyKey: string): string | null {
        let fare = getCheapestFirstClassFareForInbound(journeyKey);
        if ((fare === undefined) || (fare === null)) {
            return null;
        } else {
            return fare.priceFormatted;
        }
    }

    // Stores the fares in the two fares variables, based on the selected journey(s) and fare class. This should be called whenever the selected journey(s) change in the inbound/outbound journey selector.
    function chooseAndStoreFaresDependingOnCurrentJourneySelection() {
        // If the journey type is a return journey (not single or open return) perform different logic. A return journey has one JourneyFare that matches the in and out keys of the selected in/out journeys, which is different to Singles/Open Returns. Pick the cheapest fare from the outbound and inbound arrays in the JourneyFare, choosing the correct array based on the fare class of the selected journey.
        if (query.journeyType.value === JourneyType.Return) {
            let journeyFare = getJourneyFareForReturnJourneyKeys(selectedOutboundJourney.value.journeyKey, selectedInboundJourney.value.journeyKey);

            if (!journeyFare) return;

            if (selectedOutboundJourney.value.fareClass === 'standard') {
                selectedOutboundFares.value = [journeyFare.standardClass.singleOutFares[0]];
            } else if (selectedOutboundJourney.value.fareClass === 'first') {
                selectedOutboundFares.value = [journeyFare.firstClass.singleOutFares[0]];
            }

            if (selectedInboundJourney.value.fareClass === 'standard') {
                selectedInboundFares.value = [journeyFare.standardClass.singleInFares[0]];
            } else if (selectedInboundJourney.value.fareClass === 'first') {
                selectedInboundFares.value = [journeyFare.firstClass.singleInFares[0]];
            }

        } else {
            // If a outbound journey has been selected, then get the cheapest fare depending on the fare class selected and store it in this store.
            if (selectedOutboundJourney.value.journeyKey) {
                if (selectedOutboundJourney.value.fareClass === 'standard') {
                    let fare = getCheapestStandardClassFareForOutbound(selectedOutboundJourney.value.journeyKey)
                    if (fare) {
                        selectedOutboundFares.value = [fare.key];
                    }
                } else if (selectedOutboundJourney.value.fareClass === 'first') {
                    let fare = getCheapestFirstClassFareForOutbound(selectedOutboundJourney.value.journeyKey)
                    if (fare) {
                        selectedOutboundFares.value = [fare.key];
                    }
                }
            }

            // If a return journey has been selected, then get the cheapest fare depending on the fare class selected and store it in this store.
            if (selectedInboundJourney.value.journeyKey) {
                if (selectedInboundJourney.value.fareClass === 'standard') {
                    let fare = getCheapestStandardClassFareForInbound(selectedInboundJourney.value.journeyKey);
                    if (fare) {
                        selectedInboundFares.value = [fare.key];
                    }
                } else if (selectedInboundJourney.value.fareClass === 'first') {
                    let fare = getCheapestFirstClassFareForInbound(selectedInboundJourney.value.journeyKey);
                    if (fare) {
                        selectedInboundFares.value = [fare.key];
                    }
                }
            }
        }
    }

    // Returns an array of fare objects sorted by price ascending. These fares are all valid fares for the selected journey. If query.journeyType = JourneyType.Return, then only return fares types will be returned. If the journey search is a single, then outbound and return tickets will be returned. Used by JourneyPackages to display other fares available for the selected journey. Returns an empty array if no fares are found.
    function getAllFaresValidForJourneySelected() {
        let fareObjects: Array<FaresValueModel> = [];

        // To find other fares for the selected journey, we need to find the selected journey in the journeyFares array, then look in the returnFares array for the other return type fares.
        if (query.journeyType.value === JourneyType.Return) {
            let returnFares: Array<string> = [];
            let journeyFareOut = getJourneyFareForOutboundJourneyKey(selectedOutboundJourney.value.journeyKey!);
            let journeyFareIn = getJourneyFareForInboundJourneyKey(selectedInboundJourney.value.journeyKey!);

            returnFares = returnFares.concat(journeyFareOut?.standardClass?.returnFares);
            returnFares = returnFares.concat(journeyFareOut?.firstClass?.returnFares);
            returnFares = returnFares.concat(journeyFareIn?.standardClass?.returnFares);
            returnFares = returnFares.concat(journeyFareIn?.firstClass?.returnFares);

            // Remove duplicates
            returnFares = [...new Set(returnFares)];

            // returnFares is just an array of keys - get the full fare objects for each
            fareObjects = returnFares.map(fareKey => {
                const fareObject = getFareObjectByFareKey(fareKey);
                if (!_get(fareObject, "value", false)) {
                    console.warn("setModalData(): No fare object found for key", fareKey);
                    return;
                }
                return fareObject.value;
            });

            // Sort the fares by price
            fareObjects.sort((a, b) => a.price - b.price);
        } else {
            let fares: Array<string> = [];
            let journeyFareOut = getJourneyFareForOutboundJourneyKey(selectedOutboundJourney.value.journeyKey!);

            fares = fares.concat(journeyFareOut?.standardClass?.returnFares);
            fares = fares.concat(journeyFareOut?.standardClass?.singleOutFares);
            fares = fares.concat(journeyFareOut?.firstClass?.returnFares);
            fares = fares.concat(journeyFareOut?.firstClass?.singleOutFares);

            // Remove duplicates
            fares = [...new Set(fares)];

            // fares is just an array of keys, get the full fare objects for each
            fareObjects = fares.map(fareKey => {
                const fareObject = getFareObjectByFareKey(fareKey);
                if (!_get(fareObject, "value", false)) {
                    console.warn("setModalData(): No fare object found for key", fareKey);
                    return;
                }
                return fareObject.value;
            });

            // Sort the fares by price
            fareObjects.sort((a, b) => a.price - b.price);
        }

        if ((fareObjects === undefined) || (fareObjects.length === 1 && fareObjects[0] === undefined)) {
            return [];
        }

        return fareObjects;
    }

    // Used to decide whether to highlight the journey/fare class combination on the outbound/inbound columns in JourneyResultSelector. For singles and open returns, the API returns the cheapest overall fare so this can be used as the frontend displays cheapest fares and only outbounds. For returns, the cheapest array returns either 2 fares which is 2 singles (one for out, one for in) or a return fare, so some extra logic is needed to calculate this correctly.
    // @return boolean - true if the journey/fare class combination is the cheapest, false if not.
    function isJourneyAndClassTheCheapest(journeyKey: string, fareClass: 'standard' | 'first', type: 'outbound' | 'inbound') {
        if (query.journeyType.value === JourneyType.Return) {

            if (journeySearchResults?.value?.cheapestFares?.cheapest?.length === 2) {
                // Find which Fare key is the outbound and which is the inbound
                let outboundFareKey = journeySearchResults?.value?.cheapestFares?.cheapest?.find((fareKey) => {
                    let fare = getFareObjectByFareKey(fareKey);
                    if (fare.value.originName === query.originStation.value?.name) {
                        return true;
                    }
                });
                let inboundFareKey = journeySearchResults?.value?.cheapestFares?.cheapest?.find((fareKey) => {
                    let fare = getFareObjectByFareKey(fareKey);
                    if (fare.value.destinationName === query.originStation.value?.name) {
                        return true;
                    }
                });

                // If the fare key given is the same as the cheapest fare key for the journey, with respetct to the correct fare key, then return true.
                if (type === 'outbound') {
                    if (fareClass === 'standard') {
                        if (outboundFareKey === getCheapestStandardClassFareForOutbound(journeyKey)?.key) {
                            return true;
                        }
                    } else {
                        if (outboundFareKey === getCheapestFirstClassFareForOutbound(journeyKey)?.key) {
                            return true;
                        }
                    }
                } else if (type === 'inbound') {
                    if (fareClass === 'standard') {
                        if (inboundFareKey === getCheapestStandardClassFareForInbound(journeyKey)?.key) {
                            return true;
                        }
                    } else {
                        if (inboundFareKey === getCheapestFirstClassFareForInbound(journeyKey)?.key) {
                            return true;
                        }
                    }
                }
            } else {
                // TODO-Return ticket is cheapest???
            }


        } else {
            let result = null;
            if (fareClass === 'standard') {
                result = journeySearchResults?.value?.cheapestFares?.cheapest?.includes(getCheapestStandardClassFareForOutbound(journeyKey)?.key);
            } else {
                result = journeySearchResults?.value?.cheapestFares?.cheapest?.includes(getCheapestFirstClassFareForOutbound(journeyKey)?.key)
            }
            if ((result === undefined) || (result === false)) {
                return false
            } else {
                return true
            };
        }
    }

    return {
        query,
        journeyQuery,
        hasRailCard,
        hasViaAvoidStation,
        journeySearchResults,
        updateQueryFromRequest,
        fetchJourneysFromGqlApi,
        paginateJourneysFromGqlApi,
        journeySearchResultsPerPage,
        isJourneySearchApiLoading,
        getFareObjectByFareKey,
        getCheapestStandardClassFareForOutbound,
        getCheapestFirstClassFareForOutbound,
        getCheapestStandardClassFareForInbound,
        getCheapestFirstClassFareForInbound,
        selectedOutboundJourney,
        selectedInboundJourney,
        getJourneyFareForOutboundJourneyKey,
        getJourneyFareForInboundJourneyKey,
        getCheapestStandardClassFarePriceForOutbound,
        getCheapestFirstClassFarePriceForOutbound,
        getCheapestStandardClassFarePriceForInbound,
        getCheapestFirstClassFarePriceForInbound,
        chooseAndStoreFaresDependingOnCurrentJourneySelection,
        getJourneyByJourneyKey,
        getPricingBreakdownError,
        selectedInboundFares,
        selectedOutboundFares,
        getArrayOfAllFares,
        getJourneyFareForReturnJourneyKeys,
        getAllFaresValidForJourneySelected,
        isJourneyAndClassTheCheapest
    };
});
