<script setup lang="ts">
import type { Ref } from "vue";
import {
    ref, shallowRef, watch, inject, defineModel,
} from "vue";
import type { PlaceItem } from "@/models/basket/PlaceItem";
import {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
} from "@headlessui/vue";
import gql from "graphql-tag";
import { useApolloClient } from '@vue/apollo-composable';
const { client } = useApolloClient();

const query: Ref<string> = ref("");
const isLoading: Ref<boolean> = ref(false);
const places: Ref<Array<PlaceItem>> = ref<Array<PlaceItem>>([]);
const abortController: Ref<AbortController|null> = shallowRef(null);
const model = defineModel<PlaceItem | null>();

function stationSearch(query: string) {
    return new Promise(async (resolve, reject) => {
        try {
            let result = await client.query({
                query: gql`
                query placeQuery {
                    placeQuery(
                        query: "${query}"
                        purpose: ORIGIN
                        sort: RELEVANCE
                        limit: 30
                    ) {
                        id
                        name
                        nlc
                    }
                }`,
            });

            resolve(result.data.placeQuery);
            return;
        } catch (error) {
            console.error(error);
            reject();
            return;
        }
    })
}

async function getStations(searchTerm: string) {
    isLoading.value = true;

    if (searchTerm === "") {
        places.value = [];
        return;
    }

    try {
        if (abortController.value) {
            abortController.value.abort();
        }
        abortController.value = new AbortController();
        const getPlacesResponse = await stationSearch(searchTerm);
        places.value = getPlacesResponse;
    } catch (error) {
        console.error(error);
    } finally {
        isLoading.value = false;
    }
}

const clearValue = () => {
    query.value = "";
    model.value = null;
    places.value = [];
};

watch(query, async () => {
    // if the customer has backspaced out the query and it's now empty, don't try
    if (query.value === "") {
        if (abortController.value) {
            abortController.value.abort();
        }
        model.value = null;
        places.value = [];
    } else {
        await getStations(query.value);
    }
});
</script>

<template>
    <Combobox v-model="model">
        <ComboboxInput
            autocomplete="off"
            @change="query = $event.target.value"
            :displayValue="(item) => (item as PlaceItem)?.name"
            class="destination-selector w-full py-2 pl-3 pr-10 text-sm leading-5 text-gray-900"></ComboboxInput>
        <ComboboxOptions :class="'hui-combobox options results-'+places.length">
            <ComboboxOption
                anchor="bottom start"
                v-for="station in places"
                :key="station.id"
                :value="station"
                class="ui-active:text-white ui-not-active:bg-white ui-not-active:text-black pt-2 pb-2"
            >
                {{ station.name }}
            </ComboboxOption>
        </ComboboxOptions>
        <div class="clear-button" v-if="model">
            <v-btn tabindex="-1" href="#" elevation="0" size="" @click.prevent="clearValue">&times;</v-btn>
        </div>
    </Combobox>

</template>

<style>
.clear-button .v-btn__content {
    position: relative;
    top: -1px;
}
</style>

<style scoped lang="scss">
.options {
  list-style: none;
  font-size: 1.06rem;
  border: 1px solid var(--vt-c-lightgray);
  cursor: pointer;
}

.destination-selector {
    border: 1px solid var(--vt-c-lightgray);
    border-radius: 40px;
    width: 270px;
    &:focus, &:active {
        outline: 2px solid var(--vt-c-orange);
    }
}
/* HeadlessUI combobox */

.hui-combobox {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(3px);
    left: 0;
    right: 0;
    z-index: 999;
    border-radius: 5px;
}

.clear-button {
  display: inline-block;
  position: relative;
  left:-30px;
  margin-right: -20px;
}

.hui-combobox li[data-headlessui-state="active"] {
    background: var(--vt-c-orange) !important;
    color: #fff;
}

.hui-combobox li {
   padding-left: 0.5rem;
}

.options.results-0 {
  display: none;
}

.clear-button a {
  width: 20px;
  border: 1px solid #eee;
  border-radius: 100%;
}
</style>
