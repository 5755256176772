import { useApolloClient } from '@vue/apollo-composable';
import GetRailcards from '../queries/GetRailcards.graphql';
import type { RailCardsResponse } from '@/models/remote/RailCardsResponse';

export function getRailcards(): Promise<RailCardsResponse | null> {
    const { client } = useApolloClient();
    return new Promise(async (resolve, reject) => {
        try {
            let result = await client.query({
                query: GetRailcards,
            });

            if (result.data.railcards) {
                resolve(result.data.railcards);
                return;
            }

            resolve(null);
            return;
        } catch (error) {
            console.error(error);
            reject();
            return;
        }
    });
}