import { Duration } from "luxon";

// Takes a duration string in ISO format and returns a human-readable string (e.g. "1d 2hr 34m").
export function getFormattedDurationString(durationString: string) {
    const duration = Duration.fromISO(durationString);
    const parts = [];

    const days = duration.days;
    const hours = duration.hours;
    const minutes = duration.minutes;
    const seconds = duration.seconds;

    if (days > 0) {
        parts.push(`${days}d`);
    }
    if (hours > 0) {
        parts.push(`${hours}hr`);
    }
    if (minutes > 0) {
        parts.push(`${minutes}m`);
    }
    if (seconds > 0 && days === 0 && hours === 0) { // Only show seconds if less than an hour
        parts.push(`${seconds}s`);
    }

    return parts.join(' ');
}