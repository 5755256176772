<!-- Displays a card with a message to say that there is a cheaper alternative fare available. This would be when, for example, a user selects 2 singles but a return fare is cheaper. If no fare available, displays absolutely nothing. -->
<script setup lang="ts">
import { JourneyType } from "@/models/JourneyType";
import { useJourneyStore } from "@/stores/journey-store";
const journeyStore = useJourneyStore();
import { computed } from 'vue';

const currentSelectionIsCheapest = computed(() => {
    // To show the user that there are cheaper fares available, check to see if the first fare in the list of fares from getAllFaresValidForJourneySelected() is the cheaper than the selected fare.
    let currentSelectionIsCheapest = false;

    if (journeyStore.getAllFaresValidForJourneySelected().length === 0) {
        return true;
    }

    if (journeyStore.query.journeyType === JourneyType.Return) {
        // For return journeys, the chepeast fare in the list from getAllFaresValidForJourneySelected() needs to be compared with the price of the cheapest inbound and outbound selected journeys combined to see if the selected journeys are cheaper.
        // Add the selected inbound and outbound fares together to get the total price of the selected journey.
        let outboundFare = journeyStore.getFareObjectByFareKey(journeyStore.selectedOutboundFares[0]);
        let inboundFare = journeyStore.getFareObjectByFareKey(journeyStore.selectedInboundFares[0]);
        let totalCost = outboundFare.value.price + inboundFare.value.price;

        // If the total cost of the selected journeys is greater than the cheapest fare available, then the selected journey is not the cheapest.
        if (totalCost > journeyStore.getAllFaresValidForJourneySelected()[0]?.price) {
            currentSelectionIsCheapest = false;
        } else {
            currentSelectionIsCheapest = true;
        }
    } else if ((journeyStore.query.journeyType === JourneyType.OneWay) || (journeyStore.query.journeyType === JourneyType.OpenReturn)) {
        if (journeyStore.selectedOutboundFares[0] === journeyStore.getAllFaresValidForJourneySelected()[0].key) {
            currentSelectionIsCheapest = true;
        }
    }
    return currentSelectionIsCheapest;
});
</script>
<template>
    <!-- #ff98006e -->
    <v-card v-if="!currentSelectionIsCheapest" color="amber-lighten-2" class="elevation-1 rounded-lg mb-4">
        <v-card-text class="text-center">
            <p class="font-weight-normal mb-4">There are cheaper fares available</p>
            <!--<a class="text-decoration-underline">View</a>-->
            <v-btn variant="tonal" size="small">Show</v-btn>
        </v-card-text>
    </v-card>
</template>