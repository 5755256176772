<!-- Displays JourneyResultSelector for inbound and outbound which allows the user to select a journey. Initiates the search for journeys from the API.
@props searchQuery - A GetJourneysRequest object to use as search input to get journeys from the API.
@emit addReturnJourney - Emits when the Add Return Journey button is clicked, so actions such as show the Journey Planner should occur outside of this component. -->
<script setup lang="ts">
import { onMounted } from "vue";
import { useJourneyStore } from "@/stores/journey-store";
const journeyStore = useJourneyStore();
import _get from "lodash/get";
import type { GetJourneysRequest } from "@/models/remote/GetJourneysRequest";
import JourneyResultSelector from "@/components/JourneyResultSelector.vue";

const props = defineProps<{
    searchQuery: GetJourneysRequest
}>();

onMounted(() => {
    journeyStore.fetchJourneysFromGqlApi(props.searchQuery);
});
</script>

<template>
    <div class="journey-search-results">
        <div class="outbound_col">
            <JourneyResultSelector type="outbound" />
        </div>

        <div class="inbound_col">
            <JourneyResultSelector type="inbound" v-if="journeyStore.journeySearchResults?.inbound?.journeys?.length > 0" />

            <div class="journey cursor-pointer" style="background-color: transparent;" v-if="searchQuery.journeyType === 'OneWay'" @click="$emit('addReturnJourney')">
                <p><v-icon size="40">mdi-plus</v-icon>Add return journey</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.journey-search-results {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
}
</style>