<!-- Displays all components that make up the Journey Search Results page. -->
<script setup lang="ts">
import { onMounted, ref, Ref, toRefs } from "vue";
import { useJourneyStore } from "@/stores/journey-store";
import type { GetJourneysRequest } from "@/models/remote/GetJourneysRequest";
import JourneyResults from "@/components/JourneyResults.vue";
import JourneySelectionOverview from "@/components/JourneySelectionOverview.vue";
import JourneyPlanner from "../JourneyPlanner.vue";
const requestToString = (getJourneysReq: GetJourneysRequest) => JSON.stringify(getJourneysReq);
const journeyPlanner = ref<InstanceType<typeof JourneyPlanner> | null>(null);
import { JourneyPlannerMode } from "@/models/JourneyPlannerMode";
import { JourneyType } from "@/models/JourneyType";
const journeyPlannerMode: Ref<JourneyPlannerMode> = ref(JourneyPlannerMode.Compact);

// The request prop is the URL query passed in by Vue Router
const props = defineProps<{
    request: GetJourneysRequest
}>();

const { request } = toRefs(props);
const journeyQuery: GetJourneysRequest = request.value;
const store = useJourneyStore();

onMounted(() => {
    // The JourneyStore holds a copy of the journey query broken down into variables for easy access - update this
    store.updateQueryFromRequest(journeyQuery).then(() => {
        if (journeyPlanner.value) {
            // Required to load values into the Journey Planner from the Journey Store, which can only be done once the Journey Store query var has been updated from the URL request. The JourneyPlanner component is not reactive and does not watch the Journey Store query var.
            journeyPlanner.value.syncStoreAndPinia();
        }
    }).catch((error) => {
        console.error(error);
    });
});
</script>

<template>
    <JourneyPlanner ref="journeyPlanner" :mode="journeyPlannerMode" :enableExpand="true" />

    <div class="v-container d-flex justify-center ga-1 search-results-page">
        <JourneyResults :searchQuery="props.request" :key="requestToString(request)"
            @add-return-journey="journeyPlannerMode = JourneyPlannerMode.Default;" />

        <!-- style="flex-basis: 18%;" -->
        <JourneySelectionOverview />

    </div>
</template>
<style scoped>
@media (min-width: 960px) {
    .v-container.search-results-page {
        max-width: 97% !important;
    }
}

@media (min-width: 1380px) {
    .v-container.search-results-page {
        max-width: 92% !important;
    }
}
</style>
