<!-- Displays the validity period of a ticket. Provide a ticketDetailsKey object to the component.
@prop ticketDetailsKey - A string that is found in a Fare object that is used to find the right object in journeySearch->ticketDetailsCollection. -->
<script setup lang="ts">
import { pluralise } from "@/utilities/SimplePluraliser";
import type { TicketDetailsByKey } from "@/models/JourneySelectorViewModel";
import { useJourneyStore } from "@/stores/journey-store";
import { defineProps, ref } from "vue";
import { onMounted } from "vue";

const props = defineProps<{
    ticketDetailsKey: string;
}>();

const journeyStore = useJourneyStore();
const ticketDetails = ref({} as TicketDetailsByKey);

// When given a TicketDetails key, return the ticketDetailsCollection object that contains the ticket validity.
function getTicketDetailsByKey(key: string): TicketDetailsByKey | undefined {
    let td = journeyStore.journeySearchResults.ticketDetailsCollection.find((ticketDetails) => 
        ticketDetails.key === key
    );
    return td.value;
}

function formatValidity(days: number, months: number): string {
    let output = "";
    if (months > 0) {
        output += `${months} ${pluralise("month", months)}`;
        if (days > 0) {
            output += ` and ${days} ${pluralise("day", days)}`;
        }
    } else if (days > 0) {
        output += `${days} ${pluralise("day", days)}`;
    }
    return output;
}

onMounted(() => {
    ticketDetails.value = getTicketDetailsByKey(props.ticketDetailsKey) || {} as TicketDetailsByKey;
});
</script>

<template>
    <div class="validity">
        <p v-if="ticketDetails.validOutDays || ticketDetails.validOutMonths">
            Travel out within {{ formatValidity(ticketDetails.validOutDays, ticketDetails.validOutMonths)
            }}.
        </p>
        <p v-if="ticketDetails.validInDays || ticketDetails.validInMonths">
            Travel back within {{ formatValidity(ticketDetails.validInDays, ticketDetails.validInMonths)
            }}.
        </p>
    </div>
</template>
