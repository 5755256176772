<!-- Displays a box that provides an overview of the selected outbound/inbound journeys. Includes total price.
Pulls the selected outbound/inbound Journey Key from journey-store and calls GQL pricingBreakdown to show the price. -->
<script setup lang="ts">
import type { Ref } from "vue";
import { ref, watch, computed } from "vue";
import { useJourneyStore } from "@/stores/journey-store";
const journeyStore = useJourneyStore();
import gql from "graphql-tag";
import { useApolloClient } from '@vue/apollo-composable';
const { client } = useApolloClient();
import _get from "lodash/get";
import { useRoute } from 'vue-router';
import PriceBreakdown from "@/components/3-Basket/PriceBreakdown.vue";
import type { IPriceBreakdown } from "@/models/graphql/PriceBreakdownResponse";
import JourneySummary from "@/components/3-Basket/JourneySummary.vue";
import JourneyPackages from "@/components/3-Basket/JourneyPackages.vue";
import TicketDetails from "@/components/3-Basket/TicketDetails.vue";
import { getFormattedDurationString } from "@/utilities/getFormattedDurationString";
import CheaperFareAvailableNotification from "./1-JourneyPlanner/CheaperFareAvailableNotification.vue";
import { proceedToReservationsPageFromSearchResultsPage } from "@/utilities/proceedToReservationsPageFromSearchResultsPage";
import { useRouter } from 'vue-router';
const router = useRouter();
const td: Ref = ref(null);

const route = useRoute()
const isApiLoading = ref(false);
const pricingBreakdown: Ref<IPriceBreakdown | null> = ref(null);
const showPricingBreakdown = ref(false);
const showOtherReturnFaresModal = ref(false);
const chosenOutboundJourney = computed(() => journeyStore.getJourneyByJourneyKey(journeyStore.selectedOutboundJourney.journeyKey));
const chosenInboundJourney = computed(() => journeyStore.getJourneyByJourneyKey(journeyStore.selectedInboundJourney.journeyKey));
const journeySummaryComponent = ref({
    journeyKey: journeyStore.selectedOutboundJourney.journeyKey,
    show: false
});
const ticketDetailsComponent = ref({
    restrictionDetails: null,
    ticketDetails: null,
    fareDetails: null,
    journeyType: 'outbound',
});

// The Ticket details component requires all data via props, so collect relevant data and show the dialog.
function showTicketDetailsComponent(journeyType: 'outbound' | 'inbound') {
    let fareKey = journeyType === 'outbound' ? journeyStore.selectedOutboundFares[0] : journeyStore.selectedInboundFares[0];

    let fare = journeyStore.getFareObjectByFareKey(fareKey);
    ticketDetailsComponent.value.fareDetails = fare.value;
    let ticketDetailsKey = fare.value.ticketDetailsKey;
    let ticketRestrictionsKey = fare.value.restrictionCode;

    journeyStore.journeySearchResults.ticketRestrictionsCollection.find((restriction) => {
        if (restriction.key === ticketRestrictionsKey) {
            ticketDetailsComponent.value.restrictionDetails = restriction.value;
        }
    });

    journeyStore.journeySearchResults.ticketDetailsCollection.find((ticket) => {
        if (ticket.key === ticketDetailsKey) {
            ticketDetailsComponent.value.ticketDetails = ticket.value;
        }
    });

    ticketDetailsComponent.value.journeyType = journeyType;
    td.value.showDialog();
}

function getPricingBreakdown() {
    return new Promise(async (resolve, reject) => {
        try {
            isApiLoading.value = true
            journeyStore.getPricingBreakdownError = "";

            let fares = journeyStore.getArrayOfAllFares();

            if ((!fares.length) || (!journeyStore.selectedOutboundJourney.journeyKey)) {
                isApiLoading.value = false
                resolve(null)
                return
            }

            // Ensure each fare is wrapped in quotes
            fares = fares.map(fare => `"${fare}"`)

            let result = await client.query({
                query: gql`
                        query pricingBreakdown {
                            pricingBreakdown(
                                searchId: "${journeyStore.journeySearchResults?.searchId}"
                                outboundJourneyKey: "${journeyStore.selectedOutboundJourney.journeyKey}"
                                ${_get(journeyStore, 'selectedInboundJourney.journeyKey', false) ? `inboundJourneyKey: "${journeyStore.selectedInboundJourney.journeyKey}"` : ''}
                                fareKeys: [${fares}]
                            ) {
                                bookingAmountFormatted
                                bookingFeeFormatted
                                existingTrips {
                                    amount
                                    inboundDate
                                    isReturn
                                    outboundDate
                                    summary
                                }
                                totalPaymentAmountFormatted
                                tripAmountFormatted
                                willBookingFeeChangeDueToAddition
                            }
                        }`,
            })

            pricingBreakdown.value = result.data.pricingBreakdown

            isApiLoading.value = false

            resolve(null)
            return
        } catch (error) {
            console.error(error);
            isApiLoading.value = false;
            if (_get(error, "cause.result.errors[0].message", false)) {
                journeyStore.getPricingBreakdownError = error.cause.result.errors[0].message;
            } else {
                journeyStore.getPricingBreakdownError = "Uknown error. Please try again.";
            }
            reject()
            return
        }
    })
}

watch(
    [
        () => journeyStore.selectedInboundFares,
        () => journeyStore.selectedOutboundFares,
        () => journeyStore.selectedOutboundJourney,
        () => journeyStore.selectedInboundJourney
    ],
    async () => {
        await getPricingBreakdown();
    },
    { immediate: true }
);
</script>
<template>
    <div class="ml-8">
        <CheaperFareAvailableNotification @click="showOtherReturnFaresModal = true;" />

        <v-card class="overview elevation-1 rounded-lg">
            <v-card-text>
                <div class="total-price-wrapper">
                    <v-skeleton-loader v-if="isApiLoading || !pricingBreakdown?.totalPaymentAmountFormatted" type="text"
                        class="w-100"></v-skeleton-loader>
                    <template v-else>
                        <span>Total</span><span>{{
                            pricingBreakdown?.totalPaymentAmountFormatted
                            }}</span>
                    </template>
                </div>

                <div class="text-center mt-5">
                    <v-btn class="mb-3 mx-auto button text-white" :loading="isApiLoading" @click="proceedToReservationsPageFromSearchResultsPage(router, route)"
                        size="x-large" rounded="lg" block
                        :disabled="journeyStore.getPricingBreakdownError !== ''">Continue</v-btn>
                </div>

                <v-alert :text="journeyStore.getPricingBreakdownError" type="error"
                    v-if="journeyStore.getPricingBreakdownError !== ''" class="mb-3"></v-alert>

                <v-skeleton-loader v-if="isApiLoading" class="mb-3" type="text"></v-skeleton-loader>
                <p v-else class="d-block text-center text-caption font-weight-light">Total includes ticket price plus a
                    small {{ pricingBreakdown?.bookingFeeFormatted }} fee</p>

                <p @click="showPricingBreakdown = true;" class="pricing-breakdown-link mt-1">Pricing breakdown</p>

                <v-divider class="mt-5 mb-3" opacity="1"></v-divider>

                <v-skeleton-loader v-if="isApiLoading" class="mb-3" type="paragraph"></v-skeleton-loader>
                <div v-else class="mb-5">
                    <div v-if="chosenOutboundJourney">
                        <div class="d-flex justify-start ga-1 mb-1">
                            <v-icon style="opacity:0.8">mdi-arrow-right-box</v-icon>

                            <p>{{ chosenOutboundJourney?.originName ?? 'N/A' }}
                                to {{
                                    chosenOutboundJourney?.destinationName ?? 'N/A'
                                }}</p>
                        </div>

                        <div class="d-flex justify-space-between flex-wrap">
                            <p @click="journeySummaryComponent.journeyKey = chosenOutboundJourney?.key; journeySummaryComponent.show = true;"
                                class="text-decoration-underline cursor-pointer">{{
                                    getFormattedDurationString(chosenOutboundJourney?.totalTimeIso) ?? 'N/A' }}, {{
                                    chosenOutboundJourney?.changes ?? 0 }} change{{
                                    chosenOutboundJourney?.changes === 1 ? '' : 's' }}</p>

                            <p @click="showTicketDetailsComponent('outbound')"
                                class="text-decoration-underline cursor-pointer"
                                :class="[journeyStore.getFareObjectByFareKey(journeyStore.selectedOutboundFares[0])?.value.displayName.length > 25 ? 'w-100 text-right' : '']">
                                {{
                                    journeyStore.getFareObjectByFareKey(journeyStore.selectedOutboundFares[0])?.value.displayName
                                }}</p>
                        </div>

                        <p v-if="journeyStore.getFareObjectByFareKey(journeyStore.selectedOutboundFares[0])?.value.crossLondon"
                            class="mt-1">✠ Fare includes cost of cross-London transfer</p>

                    </div>
                    <div v-if="chosenInboundJourney">
                        <v-divider class="my-3" opacity="0.6" thickness="1"></v-divider>

                        <div class="d-flex justify-start ga-1 mb-1">
                            <v-icon style="opacity:0.8">mdi-arrow-left-box</v-icon>

                            <p>{{ chosenInboundJourney?.originName ?? 'N/A' }}
                                to {{
                                    chosenInboundJourney?.destinationName ?? 'N/A'
                                }}</p>
                        </div>

                        <div class="d-flex justify-space-between flex-wrap">
                            <p @click="journeySummaryComponent.journeyKey = chosenInboundJourney?.key; journeySummaryComponent.show = true;"
                                class="text-decoration-underline cursor-pointer">{{
                                    getFormattedDurationString(chosenInboundJourney?.totalTimeIso) ?? 'N/A' }}, {{
                                    chosenInboundJourney?.changes ?? 0 }} change{{
                                    chosenInboundJourney?.changes === 1 ? '' : 's' }}</p>

                            <p @click="showTicketDetailsComponent('inbound')"
                                class="text-decoration-underline cursor-pointer"
                                :class="[journeyStore.getFareObjectByFareKey(journeyStore.selectedInboundFares[0])?.value.displayName.length > 25 ? 'w-100 text-right' : '']">
                                {{
                                    journeyStore.getFareObjectByFareKey(journeyStore.selectedInboundFares[0])?.value.displayName
                                }}</p>
                        </div>

                        <p v-if="journeyStore.getFareObjectByFareKey(journeyStore.selectedInboundFares[0])?.value.crossLondon"
                            class="mt-1">✠ Fare includes cost of cross-London transfer</p>
                    </div>
                </div>

                <v-divider class="my-3" opacity="1"></v-divider>

                <a @click="showOtherReturnFaresModal = true;"
                    class="d-block mt-3 text-center text-decoration-underline">View other
                    fares</a>

            </v-card-text>
        </v-card>

        <PriceBreakdown :price-breakdown="pricingBreakdown" v-if="showPricingBreakdown"
            @close="showPricingBreakdown = false;" />

        <JourneySummary v-if="journeySummaryComponent.show" :journeyKey="journeySummaryComponent.journeyKey"
            @close="journeySummaryComponent.show = false;" />

        <JourneyPackages v-if="showOtherReturnFaresModal" @close="showOtherReturnFaresModal = false;" />

        <TicketDetails ref="td" :ticket-detail="ticketDetailsComponent.ticketDetails"
            :journey-fare="ticketDetailsComponent.fareDetails" :fare-type="ticketDetailsComponent.journeyType"
            :restrictionDetails="ticketDetailsComponent.restrictionDetails" />
    </div>
</template>

<style scoped>
.overview {
    height: fit-content;
}

.total-price-wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
    font-weight: 700;
}

.total-price-wrapper span {
    font-weight: 700;
}

.button {
    background-color: var(--vt-c-orange);
}

.button :deep(.v-btn__content) {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 1.3rem;
    letter-spacing: normal;
}

.pricing-breakdown-link {
    cursor: pointer;
    color: var(--vt-c-orange);
    text-decoration: underline;
    text-align: center;
}
</style>