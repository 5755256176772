import { createApp, nextTick } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import {
    email,
    required,
} from "@vee-validate/rules";
import { defineRule } from "vee-validate";

import * as Sentry from "@sentry/vue";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
import { Amplify } from "aws-amplify";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faArrowRightArrowLeft,
    faCalendar,
    faClock,
    faUpRightFromSquare,
    faCheck,
} from "@fortawesome/pro-duotone-svg-icons";

// Import specific icons
import {
    faBus,
    faCirclePlus,
    faExchange,
    faRoute,
    faShip,
    faSignIn,
    faSignOut,
    faTimesCircle,
    faArchway,
    faArrowCircleRight,
    faArrowCircleUp,
    faArrowCircleDown,
    faArrowRight,
    faArrowLeft,
    faChevronDown,
    faChevronUp,
    faChevronRight,
    faChevronLeft,
    faCircleXmark,
    faTrain,
    faTrainSubwayTunnel,
    faUserFriends,
    faAddressCard,
    faExclamationTriangle,
    faArrowUp,
    faRepeat,
    faWalking,
    faTrash,
    faEnvelopeOpenText,
    faPrint,
    faComputerClassic,
    faQrcode,
    faTicket,
    faPlus,
    faCircleCheck,
    faHourglass,
    faCircleInfo,
    faGift,
    faIdCard,
    faLock,
    faUniversity,
} from "@fortawesome/pro-solid-svg-icons";

import PrimeVue from "primevue/config";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { retailService } from "@/services/RetailService";
import { retailInstanceTypeSymbol, retailServiceSymbol } from "@/models/Symbols";
import { RetailInstanceTypeService } from "@/services/RetailInstanceTypeService";
import constructRouter from "./router";
import App from "./App.vue";
// theme
import "primevue/resources/themes/lara-light-indigo/theme.css";
// core
import "primevue/resources/primevue.min.css";

import "./assets/main.scss";

defineRule("required", required);
defineRule("email", email);

// Register the icon
// Do this for all icons you plan to use
library.add(
    faArrowRightArrowLeft,
    faCirclePlus,
    faBus,
    faCalendar,
    faClock,
    faExchange,
    faRoute,
    faShip,
    faSignIn,
    faSignOut,
    faTimesCircle,
    faArchway,
    faArrowCircleRight,
    faArrowCircleUp,
    faArrowCircleDown,
    faArrowRight,
    faChevronDown,
    faChevronUp,
    faChevronRight,
    faChevronLeft,
    faCircleXmark,
    faCircleInfo,
    faCheck,
    faTrain,
    faTrainSubwayTunnel,
    faUserFriends,
    faAddressCard,
    faExclamationTriangle,
    faUpRightFromSquare,
    faArrowUp,
    faRepeat,
    faWalking,
    faArrowLeft,
    faTrash,
    faEnvelopeOpenText,
    faPrint,
    faComputerClassic,
    faQrcode,
    faTicket,
    faPlus,
    faCircleCheck,
    faHourglass,
    faGift,
    faIdCard,
    faLock,
    faUniversity,
);

const cognitoSettings: { userPoolId: string, userPoolClientId: string } = { userPoolId: "", userPoolClientId: "" };
const instanceTypeSettings: { instanceType: string } = { instanceType: "B2C" };

/** Begin dragons */
if (window.performance && performance.getEntriesByType) {
    const navTiming = performance.getEntriesByType("navigation");
    if (navTiming.length > 0) {
        const { serverTiming } = navTiming[0] as PerformanceResourceTiming;
        if (serverTiming && serverTiming.length > 0) {
            for (let i = 0; i < serverTiming.length; i++) {
                // if name is CognitoUserPool or CognitoUserPoolClient
                // we've smuggled the data across without needing
                // another XHR
                if (serverTiming[i].name === "CognitoUserPool") {
                    cognitoSettings.userPoolId = serverTiming[i].description;
                } else if (serverTiming[i].name === "CognitoUserPoolClient") {
                    cognitoSettings.userPoolClientId = serverTiming[i].description;
                } else if (serverTiming[i].name === "RetailInstanceType") {
                    instanceTypeSettings.instanceType = serverTiming[i].description;
                }
            }
        }
    }
}
/** End dragons */

const vuetify = createVuetify({
    theme: {
        defaultTheme: "light",
    },
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
            mdi,
        },
    },
    components,
    directives,
});
const app = createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon);
app.provide(retailServiceSymbol, retailService);
const retailInstanceTypeService = new RetailInstanceTypeService(instanceTypeSettings.instanceType);
app.provide(retailInstanceTypeSymbol, retailInstanceTypeService);
const plugin = createPinia();
plugin.use(piniaPluginPersistedState);

app.use(plugin);
const router = constructRouter(retailInstanceTypeService);

router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    nextTick(() => {
        document.title = (to.meta.title as string) ? (`${to.meta.title as string} - MyTrainTicket`) : "MyTrainTicket";
    });
});

if (window.location.hostname.includes("corporate-widget")) {
    Sentry.init({
        app,
        dsn: "https://4f67506c9fb68f0f99fda3bd7a9b23c7@o4506259106365440.ingest.sentry.io/4506259135004672",
        release: "7f265ec",
        integrations: [
            Sentry.replayIntegration(),
            Sentry.browserTracingIntegration(
                {
                    router,
                },
            ),
            Sentry.feedbackIntegration({
                colorScheme: "system",
                isNameRequired: true,
                isEmailRequired: true,
            }),
        ],

        tracesSampleRate: 0.1,
    });

    const sentryReplay = Sentry.getReplay();
    if (sentryReplay) {
        sentryReplay.startBuffering();
    }
}

app.use(router);
app.use(PrimeVue);
app.use(vuetify);
app.mount("#app");

// if we don't have the cognito settings, log an error
if (!cognitoSettings.userPoolId || !cognitoSettings.userPoolClientId) {
    console.error("Cognito settings not available");
} else {
    // eslint-disable-next-line no-console
    console.info("Booting Amplify Auth with settings", cognitoSettings);
}

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: cognitoSettings.userPoolId,
            userPoolClientId: cognitoSettings.userPoolClientId,
            allowGuestAccess: true,
            signUpVerificationMethod: "code", // 'code' | 'link',
            identityPoolId: "",
        },
    },
});

// Import and create Apollo client
import { ApolloClient, InMemoryCache, ApolloLink, concat, HttpLink } from '@apollo/client/core';
import { provideApolloClient } from '@vue/apollo-composable';
import _get from 'lodash/get';
// fetchAuthSession is used by GraphQL auth, but can be initilized here as that is run only when the GQL API is called.
import { fetchAuthSession } from 'aws-amplify/auth';

// Use this Middleware function which will run on every GraphQL call, so once the user is logged in the token will be available to GraphQL.
// @ts-ignore
const authMiddleware = new ApolloLink((operation, forward) => {
    // Call Amplify function to get the active idToken required for GQL API auth.
    return fetchAuthSession()
        .then(auth_session => {
            console.log("auth_session:", auth_session);
            let token = _get(auth_session, "tokens.idToken", "").toString();

            let client_id = "";

            if (import.meta.env.VITE_ENV === "test") {
                if (instanceTypeSettings.instanceType === "B2B") {
                    client_id = "a11a575c-c137-ef11-ac11-02456a50d6f5";
                } else if (instanceTypeSettings.instanceType === "B2C") {
                    client_id = "3a34d86d-2b49-ec11-ab23-06ec366ed433";
                }
            } else if (import.meta.env.VITE_ENV === "staging") {
                if (instanceTypeSettings.instanceType === "B2B") {
                    client_id = "4f54de4d-d12f-ef11-ac11-02456a50d6f5";
                } else if (instanceTypeSettings.instanceType === "B2C") {
                    client_id = "";
                }
            } else if (import.meta.env.VITE_ENV === "production") {
                if (instanceTypeSettings.instanceType === "B2B") {
                    client_id = "4f54de4d-d12f-ef11-ac11-02456a50d6f5";
                } else if (instanceTypeSettings.instanceType === "B2C") {
                    client_id = "893d4edd-94d5-eb11-aaaa-06a9906e23b3";
                }
            } else {
                // Used in localdev
                client_id = "3a34d86d-2b49-ec11-ab23-06ec366ed433";
                //if (instanceTypeSettings.instanceType === "B2B") {
                //    client_id = "";
                //} else if (instanceTypeSettings.instanceType === "B2C") {
                //    client_id = "";
                //}
            }

            // Add the Authorization token to the headers
            operation.setContext({
                headers: {
                    authorization: token ? `Bearer ${token}` : "",
                    "GraphQL-preflight": 1,
                    "client-id": client_id,
                },
            })

            return forward(operation);
        })
        .catch(error => {
            console.error("Error in fetchAuthSession():", error);
            return forward(operation);
        })
})

let gqlApiUrl = "http://localhost:9999/gqlapi";

if (import.meta.env.VITE_ENV === "test") {
    gqlApiUrl = "https://gql.test.rocketonrail.co.uk/graphql";
} else if (import.meta.env.VITE_ENV === "staging") {
    gqlApiUrl = "https://gql.staging.rocketonrail.co.uk/graphql";
} else if (import.meta.env.VITE_ENV === "production") {
    gqlApiUrl = "https://gql.rocketonrail.co.uk/graphql";
}

const httpLink = new HttpLink({ uri: gqlApiUrl });

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
        },
        query: {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
        },
    }
});

// Provide Apollo to the whole App, allowing Pinia stores to access Apollo
provideApolloClient(apolloClient);