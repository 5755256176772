import { useApolloClient } from '@vue/apollo-composable';
import JourneySearch from '../queries/JourneySearch.graphql';

export function getJourneySearch(input) {
    const { client } = useApolloClient();
    return new Promise(async (resolve, reject) => {
        try {
            // Ensure Railcard count is a number
            if (input.railcards) {
                input.railcards = input.railcards.map((railcard) => {
                    railcard.count = Number(railcard.count);
                    return railcard;
                });
            }
            
            let result = await client.query({
                query: JourneySearch,
                variables: {
                    adults: Number(input.adults),
                    arriving: input.arriving,
                    avoid: input.avoid,
                    children: Number(input.children),
                    departing: input.departing,
                    destination: input.destination,
                    journeySort: input.journeySort,
                    origin: input.origin,
                    railcards: input.railcards,
                    returnArriving: input.returnArriving,
                    returnDeparting: input.returnDeparting,
                    returnOpen: input.returnOpen,
                    takeItems: input.takeItems,
                    via: input.via,
                },
            });

            if (result.data.journeySearch) {
                resolve(result.data.journeySearch);
                return;
            }

            resolve(null);
            return;
        } catch (error) {
            console.error(error);
            reject();
            return;
        }
    });
}