<!-- Displays a modal showing all available fares for the selected outbound/inbound journeys.
@emit close - Emits when this component should not be displayed anymore. -->
<script setup lang="ts">
import { type Ref } from "vue";
import { ref, onMounted } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useRoute } from 'vue-router';
const route = useRoute();
import TicketValiditySummary from "@/components/3-Basket/TicketValiditySummary.vue";
import { useJourneyStore } from "@/stores/journey-store";
import type { FaresValueModel } from "@/models/basket/FaresValueModel";
import _get from "lodash/get";
import { proceedToReservationsPageFromSearchResultsPage } from "@/utilities/proceedToReservationsPageFromSearchResultsPage";
const journeyStore = useJourneyStore();
import { useRouter } from "vue-router";
const router = useRouter();

const availableFares: Ref<FaresValueModel[]> = ref([]);
const emit = defineEmits(["close"]);

function isCurrentFareSelected(fareKey: string) {
    let fares = journeyStore.getArrayOfAllFares();
    if ((fares.length === 1) && (fares[0] === fareKey)) {
        return true;
    } else {
        return false;
    }
}

// Sets the outbound selected fare to the chosen fareKey, and clears the inbound selected fare.
function selectFare(fareKey: string) {
    journeyStore.selectedOutboundFares = [fareKey];
    journeyStore.selectedInboundFares = [];
    proceedToReservationsPageFromSearchResultsPage(router, route);
}

/*function formatDate(date: string | undefined) {
    return useDateFormat(date, "HH:mm").value;
}

function getAlternative(selectedFare: string, fareType: JourneyViewType) {
    const alternativeJourneyFares = journeyStore.journeySearchResults.journeyFares!.filter((fare: JourneyFaresModal) => fare.standardClass.returnFares.includes(selectedFare));
    const alternativeTimes: string[] = [];
    let formattedAlternativeTimes: string = "";
    if (fareType === JourneyViewType.Outbound) {
        const altOut = searchResults.value.allJourneys!.filter((obj) => alternativeJourneyFares.some((item) => item.journeyOutKey === obj.key));
        altOut.forEach((i) => alternativeTimes.push(formatDate(i.departing)));
        formattedAlternativeTimes = alternativeTimes.length > 0 ? `Out: ${alternativeTimes.toString()}` : "";
    } else {
        const altIn = searchResults.value.allJourneys!.filter((obj) => alternativeJourneyFares.some((item) => item.journeyInKey === obj.key));
        altIn.forEach((i) => alternativeTimes.push(formatDate(i.departing)));
        formattedAlternativeTimes = alternativeTimes.length > 0 ? `Return: ${alternativeTimes.toString()}` : "";
    }
    return formattedAlternativeTimes;
}*/

function getOriginName() {
    return journeyStore.getJourneyByJourneyKey(journeyStore.selectedOutboundJourney?.journeyKey)?.originName;
}

function getDestinationName() {
    return journeyStore.getJourneyByJourneyKey(journeyStore.selectedOutboundJourney?.journeyKey)?.destinationName;
}

onMounted(() => {
    availableFares.value = journeyStore.getAllFaresValidForJourneySelected();
});
</script>

<template>
    <div class="text-center">
        <v-dialog :model-value="true" persistent scrollable width="auto">
            <v-card>
                <v-card-title class="text-center">
                    Available Fares
                    <a class="float-end" @click="$emit('close')">
                        <font-awesome-icon icon="fa-5 fa-circle-xmark"></font-awesome-icon>
                    </a>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <div class="row pt-1 fares">
                        <div>
                            Alternative return fares from {{ getOriginName() }} to {{ getDestinationName() }}:
                            <div v-if="availableFares.length === 0">
                                <v-empty-state
                                    text="The route may be using single-leg pricing, or you may have selected an outbound/return on different days on a route without period return tickets. Please buy two singles instead."
                                    title="There are no return fares for your selected journeys"></v-empty-state>
                            </div>
                            <template v-else>
                                <div class="pb-5 mt-5 fare" v-for="(fare, i) in availableFares" :key="i">
                                    <div class="fare-title pl-5 pt-3 pb-3">
                                        {{ fare.displayName }}
                                        <a href="#" data-toggle="tooltip" data-placement="top">
                                            <span class="float-right pr-5">{{ fare.priceFormatted }}</span>
                                        </a>
                                    </div>
                                    <div class="fare-details-wrapper pl-5 pt-3">
                                        <!--{{ getAlternative(fare, JourneyViewType.Outbound) }}<br>
                                    {{ getAlternative(fare, JourneyViewType.Inbound) }}-->
                                        <div class="fd-lhs" v-if="fare.notes?.length > 0">
                                            <div class="notes" v-for="(note, n) in fare.notes" :key="n">
                                                {{ note.value }}
                                            </div>
                                        </div>
                                        <div class="notes other-information"> <!-- always display this -->
                                            <TicketValiditySummary :ticket-details-key="fare.ticketDetailsKey">
                                            </TicketValiditySummary>
                                            <p v-if="fare.restrictionCode">
                                                <!-- link out to https://www.nationalrail.co.uk/{CODE} -->
                                                <a :href="`https://www.nationalrail.co.uk/${fare.restrictionCode}`"
                                                    target="_blank">
                                                    Restriction {{ fare.restrictionCode }} applies
                                                </a>
                                            </p>
                                            <p v-else>
                                                No time restrictions apply to this ticket type.
                                                <!-- Are we using a railcard? -->
                                            </p>
                                            {{ _get(fare, 'railcards[0].code', '') !== "" ? `There may be additional
                                            restrictions
                                            due
                                            to
                                            the selected railcard. Check Ts and Cs.` : '' }}
                                        </div>
                                        <v-btn class="primary-button mr-3 fd-rhs" @click="selectFare(fare.key)"
                                            :disabled="isCurrentFareSelected(fare.key)">
                                            <div v-if="isCurrentFareSelected(fare.key)">Selected</div>
                                            <div v-else>Continue</div>
                                        </v-btn>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped lang="scss">
.v-card {

    .v-divider {
        margin: 0;
    }

    .v-card-actions {
        button {
            background-color: var(--vt-c-orange);
            color: #fff;
            text-transform: capitalize;
        }
    }

    .v-card-text {
        display: grid;
    }
}

.fare-details-wrapper {
    display: grid;
    grid-template-columns: 1fr minmax(125px, min-content);
    grid-template-rows: 1fr;
    grid-template-areas: "fd-lhs fd-rhs";
    gap: 1rem;
}

.fd-lhs {
    grid-area: fd-lhs;
}

.fd-rhs {
    grid-area: fd-rhs;
}

@media only screen and (max-width: 760px) {
    .fare-details-wrapper {
        grid-template-columns: 1fr;
        /* and reverse the order */
        grid-template-areas:
            "fd-rhs"
            "fd-lhs";
    }

}

.fares .fare {
    border: 2px solid var(--vt-c-divider-dark-1);
    border-radius: 0.3rem;
}

.fare-title {
    font-weight: 600;
    border-bottom: 2px solid var(--vt-c-divider-dark-1);

    span {
        font-weight: 600;
    }
}

.fare-body {
    min-height: 6rem;

    .notes {
        width: 64%;
    }
}

.float-right.pl-5 {
    line-height: 4;
}

.v-btn {
    box-shadow: none;
}

.fare-title {
    font-size: 0.9rem;
}
</style>
