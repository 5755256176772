<script setup lang="ts">
import type { Ref } from "vue";
import { watch } from "vue";
import { useJourneyStore } from "@/stores/journey-store";
import { useField } from "vee-validate";
import type { PlaceItem } from "@/models/basket/PlaceItem";
import GenericStationPicker from "@/components/1-JourneyPlanner/GenericStationPicker.vue";
const store = useJourneyStore();

function isStationPopulated(value: any): boolean {
    return !!(value && typeof value !== "string" && value.name);
}

function validateOriginStation(value: any) {
    if (isStationPopulated(value)) {
        return true;
    }

    return "No origin station has been specified.";
}

function validateDestinationStation(value: any) {
    if (isStationPopulated(value)) {
        return true;
    }

    return "No destination station has been specified.";
}

const originPlaceField = useField<PlaceItem>("originStation", validateOriginStation);
const originStation: Ref<PlaceItem> = originPlaceField.value;
const destinationPlaceField = useField<PlaceItem>("destinationStation", validateDestinationStation);
const destinationStation: Ref<PlaceItem> = destinationPlaceField.value;

const swapStations = () => {
    const storeOriginStation = store.query.originStation;

    store.query.originStation = store.query.destinationStation;
    store.query.destinationStation = storeOriginStation;

    originStation.value = store.query.originStation as PlaceItem;
    destinationStation.value = store.query.destinationStation as PlaceItem;
};

watch(originStation, async (newSelectedStation) => {
    store.query.originStation = newSelectedStation;
});

watch(destinationStation, async (newSelectedStation) => {
    store.query.destinationStation = newSelectedStation;
});
</script>

<template>
    <div class="row">
        <div class="col">
            <div class="horizontal-fields">
                <div class="origin-station-container ">
                    <label class="form-label">From</label>
                    <GenericStationPicker v-model="originStation"></GenericStationPicker>
                </div>

                <div class="swap-stations">
                    <font-awesome-icon id="from-to-station-switch" icon="fa-exchange"
                        @click="swapStations"></font-awesome-icon>
                </div>
                <div>
                    <div class="destination-station-container">
                        <label class="form-label">To</label>
                        <GenericStationPicker v-model="destinationStation"></GenericStationPicker>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.swap-stations {
    background-color: var(--vt-c-orange);
    height: 35px;
    width: 35px;
    border-radius: 50px;
    font-weight: normal;
    color: var(--vt-c-white);
    top: 29px;
    font-size: 25px;
    border: none;
    padding: 1px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    z-index: 1;
    line-height: 1.5;

    #from-to-station-switch {
        font-weight: normal;
        color: var(--vt-c-white);
        top: -2px;
        font-size: 23px;
        left: 6px;
        border: none;
        padding: 1px;
        border-radius: 5px;
        position: relative;
        cursor: pointer;
        z-index: 1;
        rotate: 180deg;
    }
}

.origin-station-container,
.destination-station-container {
    position: relative;
}

:deep(.v-input__control) {
    width: 270px;
}

.destination-selector {
    border: 1px solid var(--vt-c-lightgray);
    border-radius: 40px;
    width: 270px;

    &:focus,
    &:active {
        border: 2px solid var(--vt-c-orange);
        outline: none;
    }
}

.origin-station-container svg,
.destination-station-container svg {
    position: absolute;
    left: 15px;
    top: 12px;
    font-size: 1.0625rem;

    input {
        padding-left: 35px;
    }
}

.options {
    list-style: none;
    font-size: 1.06rem;
    border: 1px solid var(--vt-c-lightgray);
    cursor: pointer;
}

.options.results-0 {
    display: none;
}

@media only screen and (max-width: 760px) {
    .form-label {
        display: none;
    }

    .swap-stations {
        top: 29px;
        left: 270px;
        position: absolute;
        height: 45px;
        border: 1px solid var(--vt-c-lightgray);
        width: 45px;
        color: var(--vt-c-orange);
        background-color: white;

        #from-to-station-switch {
            top: 2px;
            left: 9px;
            font-size: 25px;
            border: none;
            position: relative;
            color: var(--vt-c-orange);
            rotate: 90deg;
        }
    }

    :deep(.horizontal-fields .p-autocomplete-input) {
        height: 45px;
        width: 315px;
        padding-left: 30px;
    }

    :deep(.v-input__control) {
        width: 290px;
    }
}

/* HeadlessUI combobox */

.hui-combobox {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(3px);
    left: 0;
    right: 0;
    z-index: 999;
    border-radius: 5px;
}

.hui-combobox li[data-headlessui-state="active"] {
    background: var(--vt-c-orange) !important;
    color: #fff;
}

.hui-combobox li {
    padding-left: 0.5rem;
}
</style>
