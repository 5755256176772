<script setup lang="ts">
import { useDateFormat, useWindowSize } from "@vueuse/core";
import { handleError } from "@/services/AlertService";
import type { Ref } from "vue";
import {
    onMounted, ref, watch, inject,
} from "vue";
import { retailInstanceTypeSymbol } from "@/models/Symbols";
import { useRoute, useRouter } from "vue-router";
import type { RetailInstanceTypeService } from "@/services/RetailInstanceTypeService";
import type {
    IBookingOverviewResponse, IGQLBookingOverview, IGQLLeg, IGQLTripsOverview, ITicketKey,
} from "@/models/graphql/BookingOverviewResponse";
import type { TicketDetailsByKey, FaresByKeyModel, TicketRestrictionSummary } from "@/models/JourneySelectorViewModel";
import { getFareForFareKey } from "@/utilities/FareFromKey";
import { JourneyViewType } from "@/models/basket/JourneyViewType";
import TicketDetails from "@/components/3-Basket/TicketDetails.vue";
import DeliveryMethod from "@/components/3-Basket/DeliveryMethod.vue";
import { getTicketDetails, getRestrictionDetails } from "@/utilities/GetTicketDetails";
import OrderCostSummary from "@/components/3-Basket/OrderCostSummary.vue";
import type { DeliveryMethods } from "@/models/basket/DeliveryMethods";
import ReservationsModal from "@/components/3-Basket/ReservationsModal.vue";
import { gqlBookingService } from "@/services/GQLService";
import { useBookingData } from "@/stores/booking-storage";
import BasketLifetime from "@/components/3-Basket/BasketLifetime.vue";
import type { ProblemDetail } from "@/api/ProblemDetail";

const BOOKING_DOES_NOT_EXIST_ERROR: string = "Booking does not exist";

const bookingOverviewDetails: Ref<IBookingOverviewResponse | null> = ref(null);
const bookingData: Ref<IGQLBookingOverview | null> = ref(null);
const journeyTypeSelection: Ref<JourneyViewType> = ref(JourneyViewType.Outbound);
const deliveryMethod: Ref<DeliveryMethods | string> = ref("");
const reservationDetails: Ref<IGQLLeg | null> = ref(null);
const ticketDetails: Ref<TicketDetailsByKey | null> = ref(null);
const fareDetails: Ref<FaresByKeyModel | null> = ref(null);
const firstOrigin: Ref<string> = ref("");
const { width } = useWindowSize();
const route = useRoute();
const router = useRouter();
const td: Ref = ref(null);
const rm: Ref = ref(null);
const isLoading: Ref<boolean> = ref(false);
const bookingID: Ref<string | null> = ref(null);
const ticketRestrictions: Ref<TicketRestrictionSummary | null> = ref(null);
const bookingStore = useBookingData();
const instanceTypeSvc = inject(retailInstanceTypeSymbol) as RetailInstanceTypeService;

function formatDate(date: string | undefined) {
    return useDateFormat(date, "DD MMMM HH:mm").value;
}
function formatTime(date: string | undefined) {
    return useDateFormat(date, "HH:mm").value;
}

function addToBasket() {
    const { bookingId } = route.query;
    router.push({ path: "/", query: { bookingId } });
}

async function getBookingDetails() {
    isLoading.value = true;
    try {
        bookingOverviewDetails.value = await gqlBookingService.getGQLBookingOverview(bookingID!.value!);
        isLoading.value = false;
    } catch (error) {
        if ((error as ProblemDetail).detail?.includes(BOOKING_DOES_NOT_EXIST_ERROR) === true) {
            bookingID.value = null;
            bookingData.value = null;
            bookingStore.bookingData = null;
        }
        isLoading.value = false;
        handleError(error);
    }

    if (!bookingOverviewDetails.value?.booking.todCollectionPossibleFromOrigin) {
        const firstOriginFromTrips = bookingOverviewDetails.value?.booking.trips.toSorted((x: IGQLTripsOverview, y: IGQLTripsOverview) => {
            const date1 = new Date(x.outboundJourney.legs[0].departing);
            const date2 = new Date(y.outboundJourney.legs[0].departing);
            return date1.getTime() - date2.getTime();
        });
        firstOrigin.value = firstOriginFromTrips![0].outboundJourney.originName!;
    }
}

function setBookingOverview() {
    bookingData.value = bookingOverviewDetails.value?.booking!;
}

async function deleteBooking(id: string) {
    isLoading.value = true;
    try {
        await gqlBookingService.deleteGQLTrip(bookingID!.value!, id);
        bookingOverviewDetails.value = await gqlBookingService.getGQLBookingOverview(bookingID!.value!);
        bookingStore.bookingData = bookingOverviewDetails.value?.booking;
        isLoading.value = false;
    } catch (error) {
        // if error has a "detail" and it includes BOOKING_DOES_NOT_EXIST_ERROR, clear the booking ID
        // because the customer has removed the last item from it.
        // in this instance it will be a ProblemDetail
        isLoading.value = false;

        if ((error as ProblemDetail).detail?.includes(BOOKING_DOES_NOT_EXIST_ERROR) === true) {
            bookingID.value = null;
            bookingData.value = null;
            bookingStore.bookingData = null;
        } else {
            handleError(error);
        }
    }
}

async function clearBasket() {
    try {
        await gqlBookingService.deleteGQLBooking(bookingID!.value!);
        bookingData.value = null;
        bookingStore.bookingData = null;
        isLoading.value = false;
    } catch (error) {
        isLoading.value = false;
        handleError(error);
    }
}

function toggleTicketDetail(selectedTicketTicket: ITicketKey, item: IGQLTripsOverview, journeyType: JourneyViewType) {
    const fareKey = selectedTicketTicket.fareKey as string;
    const fareDetailsKey = getFareForFareKey(fareKey, item.fares);
    const ticketDetail = getTicketDetails(bookingData?.value!, fareDetailsKey);
    journeyTypeSelection.value = journeyType;
    fareDetails.value = fareDetailsKey!;
    ticketDetails.value = ticketDetail!.value!;
    const restrictionKey = fareDetails.value.restrictionCode;
    if (restrictionKey !== "") {
        ticketRestrictions.value = getRestrictionDetails(bookingData.value!, restrictionKey);
    }
    td.value.showDialog();
}

async function toggleReservationsModal(reservations: IGQLLeg) {
    reservationDetails.value = reservations;
    rm.value.showDialog();
}

function setDeliveryMethod(method: DeliveryMethods | string) {
    deliveryMethod.value = method;
}

function formatSeatLabel(leg: IGQLLeg): string {
    let formattedLabel: string = "";
    if (leg.reservations.length > 0) {
        const countedPlace = leg.reservations.filter((r) => r.isCountedPlace);
        if (countedPlace.length > 0) {
            formattedLabel = leg.reservations.length > 1 ? `${leg.reservations.length} reservations` : `${leg.reservations.length} reservation`;
        } else {
            formattedLabel = leg.reservations.length > 1 ? `${leg.reservations.length} seats` : `${leg.reservations.length} seat`;
        }
    }
    return formattedLabel;
}

onMounted(() => {
    bookingID.value = bookingStore.checkExistingBooking();
    if (bookingID.value) {
        getBookingDetails();
    } else {
        bookingData.value = null;
    }
});

watch(bookingOverviewDetails, () => setBookingOverview(), { immediate: true });
</script>

<template>
    <div>
        <v-overlay v-model="isLoading" contained class="align-center justify-center">
            <v-progress-circular indeterminate :size="70" :width="7" color="primary">
            </v-progress-circular>
        </v-overlay>
        <v-container class="pa-0 pa-md-2 pb-5 bg-white">
            <v-col class="col-sm">
                <h3 v-if="bookingData === null">You have no journeys in the basket.</h3>
                <div class="head-section" v-if="bookingData !== null && bookingData?.trips.length > 0">
                    <h2 class="title pb-5">Basket</h2>
                    <v-btn @click="clearBasket()" class="mb-5 clear-basket">
                        <font-awesome-icon icon="fa-trash" class="pr-3"></font-awesome-icon>Empty your basket
                    </v-btn>
                    <v-btn @click="addToBasket()" class="ml-3 mb-5  add-basket">
                        <font-awesome-icon icon="fa-plus" class="pr-3"></font-awesome-icon>Add another journey
                    </v-btn>
                    <BasketLifetime :booking-data="bookingData"></BasketLifetime>
                </div>
                <v-row class="pt-10" v-if="bookingData !== null && bookingData?.trips.length > 0">
                    <v-table v-if="width > 760" class="pb-5">
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Journey
                                </th>
                                <th class="text-left">
                                    Type
                                </th>
                                <th class="text-left">
                                    Tickets
                                </th>
                                <th class="text-left">
                                    Date
                                </th>
                                <th class="text-left">
                                    Seats
                                </th>
                                <th class="text-left">
                                    Cost
                                </th>
                            </tr>
                        </thead>
                        <tbody v-for="(item, i) in bookingData?.trips" v-bind:key="i">
                            <tr>
                                <td>{{ item.outboundJourney.originName }} to {{ item.outboundJourney.destinationName }}</td>
                                <td>
                                    <span v-if="item.inboundJourney !== null">Return</span>
                                    <span v-else-if="item.isOpenReturn">Open Return</span>
                                    <span v-else>Single</span>
                                </td>
                                <td class="ticket-list">
                                    <a href="#" v-for="(ticket, j) in item.outboundTickets" :key="j"
                                        @click="toggleTicketDetail(ticket, item, JourneyViewType.Outbound)">
                                        {{ getFareForFareKey(ticket.fareKey, item.fares)?.displayName }}
                                    </a>
                                </td>
                                <td>{{ formatDate(item.outboundJourney.legs[0].departing) }}</td>
                                <td>
                                    <div v-for="(leg, i) in item.outboundJourney.legs" :key="i">
                                        <a href="#" @click="toggleReservationsModal(leg)">{{ formatSeatLabel(leg) }}</a>
                                    </div>
                                </td>
                                <td>{{ item.totalPriceFormatted }}</td>
                                <td><v-btn @click="deleteBooking(item.id)" class="delete" size="small">
                                        <font-awesome-icon icon="fa-trash" class="pr-3"></font-awesome-icon>Delete
                                    </v-btn></td>

                            </tr>
                            <tr v-if="item.inboundJourney !== null">
                                <td>{{ item.inboundJourney.originName }} to {{ item.inboundJourney.destinationName }}</td>
                                <td></td>
                                <td class="ticket-list">
                                    <a href="#" v-for="(ticket, j) in item.inboundTickets" :key="j"
                                        @click="toggleTicketDetail(ticket, item, JourneyViewType.Inbound)">
                                        {{ getFareForFareKey(ticket.fareKey, item.fares).displayName }}
                                    </a>
                                </td>
                                <td>{{ formatDate(item.inboundJourney.legs[0].departing) }}</td>
                                <td>
                                    <div v-for="(leg, i) in item.inboundJourney?.legs" :key="i">
                                        <a href="#" @click="toggleReservationsModal(leg)">{{ formatSeatLabel(leg) }}</a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                    <div v-else v-for="(item, i) in bookingData?.trips" v-bind:key="i">
                        <v-expansion-panels>
                            <v-expansion-panel value="header" :eager="true">
                                <v-expansion-panel-title hide-actions>
                                    <div>{{ item.outboundJourney.originName }}
                                        <font-awesome-icon v-if="item.inboundJourney !== null" icon="fa-exchange"
                                            class="pl-2 pr-2"></font-awesome-icon>
                                        <font-awesome-icon v-else icon="fa-arrow-right"
                                            class="pl-2 pr-2"></font-awesome-icon>
                                        {{ item.outboundJourney.destinationName }}
                                    </div>
                                    <div>{{ item.totalPriceFormatted }}</div>
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                    <h3>Outward Journey</h3>
                                    <div class="journey-summary pt-5 pb-13">
                                        <div class="outbound">
                                            <label>{{ item.outboundJourney.originName }}</label>
                                        </div>
                                        <div class="inbound">
                                            <label>{{ item.outboundJourney.destinationName }}</label>
                                            <label>{{ formatTime(item.outboundJourney.legs[0].arriving) }}</label>
                                        </div>
                                    </div>
                                    <h3 class="pt-3">Fare</h3>
                                    <a href="#" v-for="(ticket, j) in item.outboundTickets" :key="j"
                                        @click="toggleTicketDetail(ticket, item, JourneyViewType.Outbound)">
                                        {{ getFareForFareKey(ticket.fareKey, item.fares) }}
                                    </a>
                                    <div v-if="item.inboundJourney !== null">
                                        <h3 class="pt-5">Return Journey</h3>
                                        <div>Departs: {{ formatDate(item.inboundJourney.legs[0].departing) }}</div>
                                        <div class="journey-summary pt-5 pb-13">
                                            <div class="outbound">
                                                <label>{{ item.outboundJourney.originName }}</label>
                                                <label>{{ formatTime(item.outboundJourney.legs[0].departing) }}
                                                    <font-awesome-icon icon="fa-arrow-right"
                                                        class="pr-3"></font-awesome-icon></label>
                                            </div>
                                            <div class="inbound">
                                                <label>{{ item.inboundJourney.destinationName }}</label>
                                                <label>{{ formatTime(item.inboundJourney.legs[0].arriving) }}</label>
                                            </div>
                                        </div>
                                        <h3 class="pt-3">Fare</h3>
                                        <a href="#" v-for="(ticket, j) in item.inboundTickets" :key="j"
                                            @click="toggleTicketDetail(ticket, item, JourneyViewType.Inbound)">
                                            {{ getFareForFareKey(ticket.fareKey, item.fares) }}
                                        </a>
                                        <div class="remove pt-5 pb-5">
                                            <a><font-awesome-icon icon="fa-trash"
                                                    class="pr-3"></font-awesome-icon>Remove</a>
                                        </div>
                                    </div>
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </div>
                    <DeliveryMethod :delivery-methods="bookingData?.availableFulfilmentMethods"
                        :collection-from-origin="bookingData?.todCollectionPossibleFromOrigin"
                        @selectedDelivery="setDeliveryMethod($event)" :firstOriginStation="firstOrigin">
                    </DeliveryMethod>
                </v-row>
                <div v-if="width < 786 && bookingData !== null" class="pb-10 mt-n5">
                    <OrderCostSummary :ticket-total="bookingData?.bookingAmount" :booking-fee="bookingData?.bookingFee"
                        :total-payment="bookingData?.totalPaymentAmount" :booking-id="bookingID!"
                        :delivery-method="deliveryMethod"></OrderCostSummary>
                </div>
            </v-col>
            <v-col v-if="width > 786 && bookingData !== null" class="pt-10 pb-10 ml-3">
                <OrderCostSummary :ticket-total="bookingData?.bookingAmount" :booking-fee="bookingData?.bookingFee"
                    :total-payment="bookingData?.totalPaymentAmount" :booking-id="bookingID!"
                    :delivery-method="deliveryMethod"></OrderCostSummary>
            </v-col>
        </v-container>
        <TicketDetails ref="td" :ticket-detail="ticketDetails!" :journey-fare="fareDetails"
            :fare-type="journeyTypeSelection" :restrictionDetails="ticketRestrictions"></TicketDetails>
        <ReservationsModal ref="rm" :reservations="reservationDetails!"></ReservationsModal>
    </div>
</template>

<style scoped lang="scss">
.v-table__wrapper>table>tbody>tr>td {
    // Need to overwrite an !important from vuetify :(
    height: 35px !important;
}

thead {
    font-size: 1.2rem;
}

button {
    box-shadow: none;
}

.delete {
    background-color: var(--vt-c-blue-link);
    color: var(--vt-c-white);
    text-transform: capitalize;
}

.clear-basket {
    background-color: var(--vt-light-gray);
    color: var(--vt-c-black);
    text-transform: capitalize;
}

.add-basket {
    background-color: var(--vt-c-orange);
    color: var(--vt-c-white);
    text-transform: capitalize;
}

tbody {
    font-size: 1rem;
}

.v-container.v-locale--is-ltr.pa-0.pa-md-2.pb-5 {
    max-width: 80vw;
    display: flex;
}

:deep(table) {
    border-top: 2px solid var(--vt-c-lightgray);
    border-bottom: 2px solid var(--vt-c-lightgray);
}

.ticket-list span::after {
    content: ", ";
}

.v-col.col-sm {
    border-top: 3px solid var(--vt-c-orange);
}

.v-col.pt-10.pb-10 {
    flex: none;
    width: 25%;
    border-top: 3px solid var(--vt-c-black);
}

.ticket-list span:last-child::after {
    content: "";
}

.v-row.pt-10.pb-10.pr-10 {
    padding-left: 1.5rem;
}

@media screen and (max-width: 768px) {
    .v-container.v-locale--is-ltr.pa-0.pa-md-2.pb-5 {
        max-width: 90vw;
        display: flex;
    }

    .v-expansion-panels.v-theme--light.v-expansion-panels--variant-default {
        width: 85vw;
    }

    .journey-summary {
        svg.svg-inline--fa.fa-arrow-right.pr-3 {
            left: 70%;
        }

        .outbound {
            float: left;
            display: inline-grid;
            width: 50%;
            text-align: left;
        }

        .inbound {
            float: right;
            display: inline-grid;
            width: 50%;
            text-align: right;
        }
    }

    .v-expansion-panel-text__wrapper {
        padding: 20px 30px 20px;
    }
}
</style>
