import { useApolloClient } from '@vue/apollo-composable';
import JourneySearchById from '../queries/JourneySearchById.graphql';

export function getJourneySearchById(input) {
    const { client } = useApolloClient();
    return new Promise(async (resolve, reject) => {
        try {
            let result = await client.query({
                query: JourneySearchById,
                variables: {
                    searchId: input.searchId,
                    outboundPagination: input.outboundPagination,
                    inboundPagination: input.inboundPagination,                   
                },
            });

            if (result.data.journeySearchById) {
                resolve(result.data.journeySearchById);
                return;
            }

            resolve(null);
            return;
        } catch (error) {
            console.error(error);
            reject();
            return;
        }
    });
}