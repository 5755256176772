<script setup lang="ts">
import { watch, computed, onMounted } from "vue";
import { JourneyType } from "@/models/JourneyType";
import { JourneyTimeType } from "@/models/JourneyTimeType";
import { useJourneyStore } from "@/stores/journey-store";
import Calendar from "primevue/calendar";
import { useField } from "vee-validate";
import { toShortDate } from "@/utilities/DateFunctions";
import { useWindowSize } from "@vueuse/core";
import { dateStringFormatter } from "@/utilities/TimeFormatter";

const { width } = useWindowSize();

const outboundDateField = useField<Date>("outboundDate", () => true);
const outboundDate = outboundDateField.value;

const outboundHourField = useField<number>("outboundHour", () => true);
const outboundHour = outboundHourField.value;
outboundHour.value = outboundDate.value?.getHours();

const outboundMinuteField = useField<number>("outboundMinute", () => true);
const outboundMinute = outboundMinuteField.value;
outboundMinute.value = outboundDate.value?.getMinutes();

const returnDateField = useField<Date>("returnDate", () => true);
const returnDate = returnDateField.value;

const returnHourField = useField<number>("returnHour", () => true);
const returnHour = returnHourField.value;
returnHour.value = returnDate.value?.getHours();

const returnMinuteField = useField<number>("returnMinute", () => true);
const returnMinute = returnMinuteField.value;
returnMinute.value = returnDate.value?.getMinutes();

const store = useJourneyStore();
const minDate = new Date();

const formattedOutboundTime = computed(dateStringFormatter(store.query.outboundDate));
const formattedReturnTime = computed(dateStringFormatter(store.query.returnDate));
function toToday() {
    // Initialise the date to now, but round up to the nearest 15 minute interval
    const interval = 15 * 60 * 1000;
    const today = new Date(Math.ceil(new Date().getTime() / interval) * interval);
    outboundDate.value = today;
    outboundHour.value = today.getHours();
    outboundMinute.value = today.getMinutes();
    returnHour.value = today.getHours();
    returnMinute.value = today.getMinutes();
}
function toTomorrow() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    outboundDate.value = tomorrow;
    returnDate.value = tomorrow;
}

function toSameDay() {
    returnDate.value = store.query.outboundDate;
    returnDate.value.setHours(outboundHour.value);
}
function toNextDay() {
    const outbound = new Date(outboundDate.value.toDateString());
    outbound.setDate(outbound.getDate() + 1);
    returnDate.value = new Date(outbound);
}
function validateReturnDate(outboundDateToValidateAgainst: Date, setReturnDate: Date): void {
    if (outboundDateToValidateAgainst > setReturnDate) {
        const newDate = new Date(outboundDateToValidateAgainst);
        newDate.setDate(newDate.getDate() + 1);
        store.query.returnDate = newDate;
        returnDate.value = newDate;
    }
}

const journeyType = computed({
    get: () => store.query.journeyType,
    set: (value: JourneyType) => {
        store.query.journeyType = value;
    }
});

watch(outboundDate, async (newOutboundDate: Date) => {
    validateReturnDate(newOutboundDate, store.query.returnDate);
    newOutboundDate.setHours(outboundHour.value);
    newOutboundDate.setMinutes(outboundMinute.value);
    store.query.outboundDate = newOutboundDate;
});

watch(outboundHour, async (newOutboundHour: number) => {
    const currentStoredDate = store.query.outboundDate;
    currentStoredDate.setHours(newOutboundHour);
});

watch(outboundMinute, async (newOutboundMinute: number) => {
    const currentStoredDate = store.query.outboundDate;
    currentStoredDate.setMinutes(newOutboundMinute);
});

watch(returnDate, async (newReturnDate: Date) => {
    newReturnDate.setHours(returnHour.value);
    newReturnDate.setMinutes(returnMinute.value);
    store.query.returnDate = newReturnDate;
});

watch(returnHour, async (newReturnHour: number) => {
    const currentStoredDate = store.query.returnDate;
    currentStoredDate.setHours(newReturnHour);
});

watch(returnMinute, async (newReturnMinute: number) => {
    const currentStoredDate = store.query.returnDate;
    currentStoredDate.setMinutes(newReturnMinute);
});

onMounted(() => {
    validateReturnDate(outboundDate.value, store.query.returnDate);
});

</script>

<template>
    <div>
        <div class="row">
            <div class="col-1">
                <label class="form-label">Type</label>
            </div>
            <div class="section-header">Ticket Type</div>
            <div class="col">
                <div class="horizontal-fields radio-type default-options">
                    <div>
                        <font-awesome-icon icon="fa-arrow-up"></font-awesome-icon>
                        <input class="form-control" type="radio" name="journey-type" id="one-way-journey"
                               :value="JourneyType.OneWay" checked="true" v-model="journeyType">

                        <label class="form-check-label one-way" for="one-way-journey">One Way</label>
                    </div>
                    <div>
                        <font-awesome-icon icon="fa-exchange"></font-awesome-icon>
                        <input class="form-control" type="radio" name="journey-type" id="return-journey"
                               :value="JourneyType.Return" v-model="journeyType">
                        <label class="form-check-label return" for="return-journey">Return</label>
                    </div>
                    <div>
                        <font-awesome-icon icon="fa-repeat"></font-awesome-icon>
                        <input class="form-control" type="radio" name="journey-type" id="open-return-journey"
                               :value="JourneyType.OpenReturn" v-model="journeyType">

                        <label class="form-check-label open-return" for="open-return-journey">Open Return</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-header">Date and time</div>
        <div class="row">
            <div class="col-1">
            </div>
            <div class="col">
                <div class="horizontal-fields today">
                    <a class="travel-day-shortcut" @click="toToday">Today</a>
                    <a class="travel-day-shortcut" @click="toTomorrow">Tomorrow</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-1">
                <label class="form-label">Out</label>
            </div>
            <div class="col">
                <div class="horizontal-fields">
                    <div id="outbound-date-container">
                        <div  v-if="width <= 760">
                            Outbound: <button class="mobile-outbound"> <RouterLink class="nav-link pl-3 pr-3" to="/date-time-selection?outbound">
                                {{ `Dep: ${toShortDate(outboundDate.toString())} ${formattedOutboundTime}` }}
                            </RouterLink></button>
                        </div>

                        <Calendar v-model="outboundDate" dateFormat="dd/mm/yy" :manualInput="false" showIcon :minDate="minDate"
                                  appendTo="self" :inputClass="{ 'p-invalid': outboundDateField.errorMessage.value }" ></Calendar>
                    </div>
                    <div id="return-date-container" v-if="store.query.journeyType === JourneyType.Return">
                        <div  v-if="width <= 760">
                            Return: <button class="mobile-outbound"> <RouterLink class="nav-link pl-3 pr-3" to="/date-time-selection?return">
                                {{ `Ret: ${toShortDate(returnDate.toString())} ${formattedReturnTime} ` }}
                            </RouterLink></button>
                        </div>
                    </div>

                    <div class="type-selection">
                        <div id="outbound-type-container" class="departing-type">
                            <select class="form-control outbound-time-type" v-model="store.query.outboundTimeType">
                                <option :value="JourneyTimeType.DepartingAt">Departing At</option>
                                <option :value="JourneyTimeType.ArrivingBefore">Arriving Before</option>
                            </select>
                        </div>
                        <div id="outbound-hour-container" class="col-1 time-selection">
                            <select class="form-control" v-model.number="outboundHour"
                                    :inputClass="{ 'p-invalid': outboundHourField.errorMessage.value }">
                                <option v-for="n in 23" :key="n" :value="n">
                                    {{ n.toString().padStart(2, '0') }}
                                </option>
                            </select>
                        </div>
                        <div id="outbound-minute-container" class="col-1 time-selection">
                            <select class="form-control" v-model.number="outboundMinute"
                                    :inputClass="{ 'p-invalid': outboundMinuteField.errorMessage.value }">
                                <option value="0">00</option>
                                <option value="15">15</option>
                                <option value="30">30</option>
                                <option value="45">45</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="return-date-container" v-if="store.query.journeyType === JourneyType.Return">
            <div class="row">
                <div class="col-1">
                </div>
                <div class="col">
                    <div class="horizontal-fields">
                        <a class="travel-day-shortcut" @click="toSameDay">Same Day</a>
                        <a class="travel-day-shortcut" @click="toNextDay">Next Day</a>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-1">
                    <label class="form-label">Return</label>
                </div>
                <div class="col">
                    <div class="horizontal-fields">
                        <div id="return-date-container">
                            <Calendar v-model="returnDate" dateFormat="dd/mm/yy" :manualInput="false" showIcon
                                      :minDate="outboundDate" appendTo="self"
                                      :inputClass="{ 'p-invalid': returnDateField.errorMessage.value }" ></Calendar>
                        </div>
                        <div class="type-selection pl-2">
                            <div id="return-type-container">
                                <select class="form-control" v-model="store.query.returnTimeType">
                                    <option :value="JourneyTimeType.DepartingAt">Departing At</option>
                                    <option :value="JourneyTimeType.ArrivingBefore">Arriving Before</option>
                                </select>
                            </div>
                            <div id="return-hour-container" class="col-1 time-selection">
                                <select class="form-control" v-model.number="returnHour"
                                        :class="{ 'p-invalid': returnHourField.errorMessage.value }">
                                    <option v-for="n in 23" :key="n" :value="n">
                                        {{ n.toString().padStart(2, '0') }}
                                    </option>
                                </select>
                            </div>
                            <div id="return-minute-container" class="col-1 time-selection">
                                <select class="form-control" v-model.number="returnMinute"
                                        :inputClass="{ 'p-invalid': outboundMinuteField.errorMessage.value }">
                                    <option value="0">00</option>
                                    <option value="15">15</option>
                                    <option value="30">30</option>
                                    <option value="45">45</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.radio-type input[type="radio"] {
    display: none;
}

.radio-type input[type="radio"]:checked+label {
    background-color: var(--vt-c-orange);
    color: var(--vt-c-white);
}

.radio-type label {
    font-weight: normal;
    display: inline-block;
    background-color: transparent;
    border: 2px solid var(--vt-c-orange);
    border-radius: 5px;
    color: var(--vt-c-orange);
    cursor: pointer;
    height: 35px;
    width: 110px;
    padding: 0.25rem 0.5rem;
    line-height: 1.5;
    border-radius: 20px;
}
.type-selection {
    display: inline-flex;
}
div#outbound-type-container select,
div#return-type-container select {
    padding-right: 20px;
    margin-top: -2px;
}
.time-selection select {
    margin-top: -2px;
}
.travel-day-shortcut {
    font-weight: bold;
    padding-right: 50px;
}
select {
    -webkit-appearance: auto;
}
.type-selection div {
    border: 1px solid  var(--vt-light-gray);
    border-radius: 20px;
    height: 37px;
    padding: 0px 7.5px;
    line-height: 2rem;
}
.p-calendar-w-btn {
    height: 37px;
}
:deep(button.p-button.p-component.p-datepicker-trigger) {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
:deep(input.p-inputtext.p-component) {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
:deep(.p-button) {
    background: var(--vt-c-orange);
    border: 1px solid var(--vt-c-orange);
}
:deep(input.p-inputtext.p-component) {
    padding-left: 15px;
}
.horizontal-fields.radio-type.default-options svg {
    display:none;
}
.horizontal-fields.radio-type.default-options div {
    text-align: center;
}
@media only screen and (max-width: 760px) {
    .type-selection {
        padding-top: 10px;
        display: none;
        div {
            padding: 0px 4px;
        }
    }
    .horizontal-fields.default-options {
        padding-top: 35px;
        flex-wrap: nowrap;
    }
    .p-calendar-w-btn {
        height: 45px;
        width: 300px;
    }
    .col:has(.default-options) {
        margin-left: -15px;
        margin-right: 12px;
        margin-top: 20px;
    }
    .radio-type label {
        width: 95px;
        text-align: center;
        height: 64px;
        width: 98px;
    }
    label.form-check-label.one-way,
    label.form-check-label.return {
        line-height: 2.95rem;
        height: 63px;
    }
    .time-selection select{
        width: 58px;
    }
    .horizontal-fields.today {
        padding-left: 35px;
        padding-top: 10px;
    }
    .horizontal-fields.radio-type.default-options svg {
        display: block;
        position: relative;
        top: -20px;
        left: 43px;
        color: var(--vt-c-orange);
        scale: 1.5;
    }
    .travel-day-shortcut {
        display: none;
    }
    .p-calendar {
        display: none;
    }
    div#outbound-date-container button,
    #return-date-container button  {
        margin-left: 0.6rem;
        width: fit-content;
        border: 1px solid var(--vt-c-orange);
        border-radius: 20px;
        height: 40px;
    }
    #return-date-container button.mobile-outbound {
        margin-left: 2rem;
    }
}
</style>
