import { useJourneyStore } from '@/stores/journey-store';
import type { Router } from 'vue-router';

// Use this function to navigate to the seat reservation page from the search results page. Fills out the correct URL parameters needed for the seat reservation page.
// @param router - The Vue Router object.
// @param route - A Router object where the bookingId will be extracted from, if present.
export function proceedToReservationsPageFromSearchResultsPage(router: Router, route: object) {
    const journeyStore = useJourneyStore();

    // If any legs on the selected journeys contain mandatory seat reservations, or if the fares selected require mandatory seat reservations, return true.
    function hasAnyMandatoryReservations() {
        let outbound = journeyStore.getJourneyByJourneyKey(journeyStore.selectedOutboundJourney.journeyKey);
        let inbound = journeyStore.getJourneyByJourneyKey(journeyStore.selectedInboundJourney.journeyKey);
        let found = false;

        if (outbound && outbound.legs.some((leg) => leg.seatReservationLevel === "REQUIRED")) {
            found = true;
        }

        if (inbound && inbound.legs.some((leg) => leg.seatReservationLevel === "REQUIRED")) {
            found = true;
        }

        journeyStore.selectedOutboundFares.forEach((fareKey) => {
            let fare = journeyStore.getFareObjectByFareKey(fareKey);
            if (fare.value.isAdvance) {
                found = true;
            }
        });

        journeyStore.selectedInboundFares.forEach((fareKey) => {
            let fare = journeyStore.getFareObjectByFareKey(fareKey);
            if (fare.value.isAdvance) {
                found = true;
            }
        });

        return found;
    };

    const { bookingId } = route.query;

    if (bookingId) {
        router.push({
            path: "/seat-reservation/",
            query: {
                bookingId,
                fareKeys: journeyStore.getArrayOfAllFares(),
                searchQuery: journeyStore.journeySearchResults?.searchId,
                outKey: journeyStore.selectedOutboundJourney.journeyKey,
                inKey: journeyStore.selectedInboundJourney.journeyKey,
                searchId: journeyStore.journeySearchResults?.searchId,
                hasAnyMandatoryReservations: hasAnyMandatoryReservations().toString(),
            },
        });
    } else {
        router.push({
            path: "/seat-reservation/",
            query: {
                fareKeys: journeyStore.getArrayOfAllFares(),
                searchQuery: journeyStore.journeySearchResults?.searchId,
                outKey: journeyStore.selectedOutboundJourney.journeyKey,
                inKey: journeyStore.selectedInboundJourney.journeyKey,
                searchId: journeyStore.journeySearchResults?.searchId,
                hasAnyMandatoryReservations: hasAnyMandatoryReservations().toString(),
            },
        });
    }
}