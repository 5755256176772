<!-- Displays outbound or return journeys and prices to allow the user to select a journey. Shows the cheapest standard and first class fares for each journey. Selecting a Journey sets a variable in the Journey Store containing the fare class and journey key, and then chooseAndStoreFaresDependingOnCurrentJourneySelection() sets the actual fares needed to continue.
@props type - A String of either "inbound" or "outbound" which describes whether this component should be looking at inbound or outbound journeys. -->
<script setup lang="ts">
import { useJourneyStore } from "@/stores/journey-store";
const journeyStore = useJourneyStore();
import _get from "lodash/get";
import { DateTime } from "luxon";
import { defineProps, computed, ref } from "vue";
import { getFriendlyDateFromDateTimeIso } from "@/utilities/getFriendlyDateFromDateTimeIso";
import { getTimeFromDateTimeIso } from "@/utilities/getTimeFromDateTimeIso";
import JourneySummary from "@/components/3-Basket/JourneySummary.vue";
import { getFormattedDurationString } from "@/utilities/getFormattedDurationString";

const props = defineProps<{
    type: 'inbound' | 'outbound'
}>();
const journeySummaryDetails = ref({
    journeyKey: '',
    journeyType: '',
    show: false
});

// For changing the selected journey vModel based on the type of journey.
const selectedJourney = computed({
    get() {
        return props.type === 'outbound'
            ? journeyStore.selectedOutboundJourney
            : journeyStore.selectedInboundJourney;
    },
    set(value) {
        if (props.type === 'outbound') {
            journeyStore.selectedOutboundJourney = value;
        } else {
            journeyStore.selectedInboundJourney = value;
        }
        journeyStore.chooseAndStoreFaresDependingOnCurrentJourneySelection();
    }
});

const getStandardClassPrice = (journeyKey: string) =>
    props.type === 'outbound'
        ? journeyStore.getCheapestStandardClassFarePriceForOutbound(journeyKey)
        : journeyStore.getCheapestStandardClassFarePriceForInbound(journeyKey);

const getFirstClassPrice = (journeyKey: string) =>
    props.type === 'outbound'
        ? journeyStore.getCheapestFirstClassFarePriceForOutbound(journeyKey)
        : journeyStore.getCheapestFirstClassFarePriceForInbound(journeyKey);

// The date for the first journey should always be shown, and then the date needs to be shown again when the day changes.
function shouldDateBeShownInJourney(index: number, type: 'outbound' | 'inbound') {
    if (index === 0) {
        return true;
    }

    let previousDate = DateTime.fromISO(journeyStore.journeySearchResults[type].journeys[index - 1].departing).toISODate();
    let currentDate = DateTime.fromISO(journeyStore.journeySearchResults[type].journeys[index].departing).toISODate();

    if (currentDate !== previousDate) {
        return true;
    }

    return false;
}
</script>

<template>

    <template v-if="journeyStore.isJourneySearchApiLoading">
        <div v-for="index in journeyStore.journeySearchResultsPerPage" :key="index" class="journey">
            <v-skeleton-loader type="paragraph" class="loading-skeleton"></v-skeleton-loader>
        </div>
    </template>
    <v-card class="rounded-lg" v-else>
        <v-card-text class="pa-0">
            <v-btn @click="journeyStore.paginateJourneysFromGqlApi('earlier', type)"
                :loading="journeyStore.isJourneySearchApiLoading" prepend-icon="mdi-chevron-up" variant="outlined"
                class="inverted-primary-button paginate-button"
                :disabled="!journeyStore.journeySearchResults[type]?.pagination?.hasPreviousPage">Earlier</v-btn>

            <div style="min-height: 65vh;" v-if="journeyStore.journeySearchResults[type]?.journeys">
                <div v-for="(journey, index) in journeyStore.journeySearchResults[type]?.journeys" :key="journey.id"
                    class="journey-wrapper">

                    <div class="date-header" v-if="shouldDateBeShownInJourney(index, type)">
                        <p class="date-string">{{
                            getFriendlyDateFromDateTimeIso(journeyStore.journeySearchResults[type].journeys[index].departing)
                            }}
                        </p>
                        <p class="standard">Standard</p>
                        <p class="first">1st Class</p>
                    </div>

                    <div class="journey">
                        <div class="times-wrapper">
                            <div class="journey-start">
                                <div class="out-time-wrapper">
                                    <p class="journey-time">{{ getTimeFromDateTimeIso(journey.departing) }}</p>
                                    <div class="flex-grow-1 d-flex justify-end"><v-icon class="direction-icon"
                                            size="24">mdi-arrow-right</v-icon></div>
                                </div>
                                <p class="place">{{ journey.originName }}</p>
                            </div>

                            <div class="journey-end">
                                <p class="journey-time">{{ getTimeFromDateTimeIso(journey.arriving) }}</p>
                                <p class="place" style="flex-basis: 100%;">{{ journey.destinationName }}</p>
                            </div>
                        </div>

                        <div class="standard-fare-selection">
                            <span v-if="getStandardClassPrice(journey.key) == null" class="not-available">Not
                                Available</span>

                            <v-radio-group v-model="selectedJourney" hide-details v-else>
                                <v-radio :label="getStandardClassPrice(journey.key)"
                                    :value="{ journeyKey: journey.key, fareClass: 'standard' }">
                                    <template v-slot:label="{ label }">
                                        <span
                                            :class="{ 'cheapest-radio-label rounded': journeyStore.isJourneyAndClassTheCheapest(journey.key, 'standard', type) }">{{
                                                label }}
                                            <span class="cheapest-text">Cheapest</span>
                                        </span>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </div>

                        <div class="first-fare-selection">
                            <span v-if="getFirstClassPrice(journey.key) == null" class="not-available">Not
                                Available</span>
                            <v-radio-group v-model="selectedJourney" v-else hide-details>
                                <v-radio :label="getFirstClassPrice(journey.key)"
                                    :value="{ journeyKey: journey.key, fareClass: 'first' }">
                                    <template v-slot:label="{ label }">
                                        <span
                                            :class="{ 'cheapest-radio-label rounded': journeyStore.isJourneyAndClassTheCheapest(journey.key, 'first', type) }">{{
                                                label }}
                                            <span class="cheapest-text">Cheapest</span>
                                        </span>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </div>

                        <div class="journey-meta">
                            <p @click="journeySummaryDetails.journeyKey = journey.key; journeySummaryDetails.show = true"
                                class="cursor-pointer">
                                <img v-if="journey.overtaken" src="@/assets/overtaken-icon-filled.svg"
                                    style="width: 20px; margin-right: 4px; vertical-align: middle; opacity: 0.7"
                                    title="Overtaken by another train">
                                {{ getFormattedDurationString(journey.totalTimeIso) }}, {{ journey.changes }} change{{
                                    journey.changes
                                        === 1 ? 's' : '' }}
                            </p>
                        </div>

                        <div v-if="journey.key === journeyStore.journeySearchResults[type].fastestJourneyKey"
                            class="fastest">
                            Fastest</div>

                    </div>
                </div>
            </div>

            <v-btn @click="journeyStore.paginateJourneysFromGqlApi('later', type)"
                :loading="journeyStore.isJourneySearchApiLoading" prepend-icon="mdi-chevron-down" variant="outlined"
                class="inverted-primary-button paginate-button"
                :disabled="!journeyStore.journeySearchResults[type]?.pagination?.hasNextPage">Later</v-btn>

            <JourneySummary :journeyKey="journeySummaryDetails.journeyKey" v-if="journeySummaryDetails.show"
                @close="journeySummaryDetails.show = false;" />

        </v-card-text>
    </v-card>
</template>

<style scoped>
.loading-skeleton {
    width: 100%;
    grid-column: 1 / 3;
    padding: 15px 0;
}

.date-header {
    width: 100%;
    border-bottom: #ccc 1px solid;
    background-color: #f7f7f7;
    display: grid;
    padding: 8px 10px;
}

.date-header .date-string,
.date-header .standard,
.date-header .first {
    grid-column: 1/1;
    grid-row: 1/1;
}

.date-header .standard {
    grid-column: 2/2;
}

.date-header .first {
    grid-column: 3/3;
}

.date-header p {
    font-weight: bold;
}

.journey,
.date-header {
    grid-template-columns: 16vw 7vw 7vw;
}

.journey {
    display: grid;
    padding: 14px 12px;
    background-color: #fff;
}

.outbound_col>.v-card,
.inbound_col>.v-card {
    overflow: visible;
}

:deep(.v-radio-group .v-label) {
    opacity: 1;
    font-size: 1.1rem;
}

.paginate-button {
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
}

.paginate-button:first-of-type {
    border-bottom: 1px solid #cccccc;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.paginate-button:last-of-type {
    border-top: 1px solid #cccccc;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.journey-wrapper {
    border-bottom: 1px solid #ccc;
}

.journey-wrapper:last-of-type {
    border-bottom: none;
}

.journey .journey-start,
.journey .journey-end {
    width: 130px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    row-gap: 4px;
}

.journey .not-available {
    font-style: italic;
    opacity: 0.5;
    white-space: nowrap;
}

.journey .times-wrapper {
    grid-row: 1/1;
    display: flex;
    column-gap: 20px;
}

.journey .times-wrapper .journey-start .out-time-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.journey .standard-fare-selection,
.journey .first-fare-selection {
    display: flex;
    align-items: center;
}

.journey .standard-fare-selection {
    grid-column: 2/2;
}

.journey .first-fare-selection {
    grid-column: 3/3;
}

.journey .standard-fare-selection>div.v-input,
.journey .first-fare-selection>div.v-input {
    margin-left: -11px;
}

.journey .journey-time {
    font-size: 1.6rem;
    font-weight: bold;
}

.journey .direction-icon {
    opacity: 0.5
}

.journey .place {
    font-size: .8rem;
    flex-basis: 100%;
}

.journey .fastest {
    align-self: center;
    display: inline-block;
    position: absolute;
    right: -25px;
    top: 0;
    height: 100%;
    writing-mode: vertical-lr;
    border-radius: 0 7px 7px 0;
    width: 25px;
    line-height: normal;
    padding: 0 5px 0 3px;
    text-align: center;
    font-size: 1rem;
    background-color: #00BCD4;
    color: #fff;
}

.journey .journey-meta {
    grid-row: 2/2;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.journey .cheapest {
    grid-column: 2 / 2;
    grid-row: 2 / 2;
    font-size: 85%;
    text-align: center;
    width: fit-content;
}

.journey .cheapest p {
    color: #000;
    background-color: #ccd05e;
    padding: 1px 11px;
}

.journey .cheapest.first {
    grid-column: 3/3;
    grid-row: 2/2;
}

.standard-fare-selection :deep(.v-label),
.first-fare-selection :deep(.v-label) {
    overflow: visible;
}

.standard-fare-selection :deep(.v-label) .cheapest-text,
.first-fare-selection :deep(.v-label) .cheapest-text {
    display: none;
}

.cheapest-radio-label {
    background-color: #f7f700;
    font-weight: bold;
    padding: 1px 5px;
    position: relative;
}

.standard-fare-selection :deep(.v-label) .cheapest-radio-label>.cheapest-text,
.first-fare-selection :deep(.v-label) .cheapest-radio-label>.cheapest-text {
    display: block;
    transform: translateX(-50%);
    position: absolute;
    bottom: -22px;
    left: 49%;
    font-size: 13px;
    white-space: nowrap;
    font-style: italic;
}
</style>