<script setup lang="ts">

import {
    type Ref, ref, watch, inject,
} from "vue";
import { useJourneyStore } from "@/stores/journey-store";
import { useField } from "vee-validate";
import type { RailCardItem } from "@/models/remote/RailCardsResponse";
import { getRailcards } from "@/graphql/functions/getRailcards";

const props = defineProps<{
    numberOfPassengers: number
}>();

const store = useJourneyStore();
const selectedRailcardField = useField<RailCardItem | null>("railCard", () => true);
const numberOfRailcardsField = useField<number>("numberOfRailcards", () => true, {
    initialValue: 1,
});
const railCard = selectedRailcardField.value;
const numberOfRailcards = numberOfRailcardsField.value;
const addingRailcard: Ref<Boolean> = ref(false);
const railcards: Ref<Array<RailCardItem>> = ref<Array<RailCardItem>>([]);
const isApiLoading = ref(false);

function loadRailcards() {
    return new Promise(async (resolve, reject) => {
        try {
            isApiLoading.value = true

            const result = await getRailcards()

            railcards.value = result
            isApiLoading.value = false

            resolve(null)
            return
        } catch (error) {
            console.error(error)
            isApiLoading.value = false
            reject()
            return
        }
    })
}

loadRailcards()

watch(selectedRailcardField.value, async (newSelectedRailCard) => {
    store.query.railCard = newSelectedRailCard ?? undefined;

    if (newSelectedRailCard == null) {
        addingRailcard.value = false;
    }
});

watch(numberOfRailcardsField.value, async (newRailcardQuantity) => {
    store.query.railCardQuantity = newRailcardQuantity;
});

</script>

<template>
    <div class="row" v-if="!addingRailcard">
        <div class="rail-card-addition">
            <a id="add-railcard-button" @click="addingRailcard = true">
                <font-awesome-icon icon="fa-address-card"></font-awesome-icon>
                Add railcard
            </a>
        </div>
    </div>
    <div class="row" v-if="addingRailcard || store?.hasRailCard">
        <div class="col-1">
            <label class="form-label">Railcards</label>
        </div>
        <div class="col">
            <div class="horizontal-fields">
                <div>
                    <v-select v-model="railCard" density="compact" class="railcard-select" return-object
                        :clearable="true" :items="railcards" item-title="name">
                    </v-select>

                </div>
                <div class="col-1">
                    <select class="form-control number-of-pax-for-railcard" v-model.number="numberOfRailcards">
                        <option v-for="n in props.numberOfPassengers" :key="n">
                            {{ n }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped lang="scss">
#add-railcard-button {
    color: var(--vt-c-orange);
    font-weight: bold;
}

:deep(.railcard-select > .v-input__control) {
    width: 370px;
}

select {
    -webkit-appearance: auto;
    padding: 10px;
    border: 1px solid var(--vt-c-lightgray);
    border-radius: 20px;
}

button.v-btn {
    box-shadow: none;
}

@media only screen and (max-width: 760px) {
    .offset-1.rail-card-addition {
        padding-top: 10px;
    }

    :deep(.railcard-select > .v-input__control) {
        width: 300px;
    }

    button.v-btn.v-btn--elevated svg {
        position: relative;
        right: -25px;
    }
}
</style>
