import ApiClient from "@/api/ApiClient";
import { type IRetailApiClient, RetailApiClient } from "@/api/RetailApiClient";
import type { SimplePlaceModel } from "@/models/basket/SimplePlaceModel";
import type { PlaceItem } from "@/models/basket/PlaceItem";
import type { RailCardItem } from "@/models/remote/RailCardsResponse";
import type { GetJourneysRequest } from "@/models/remote/GetJourneysRequest";
import type { GetPlacesResponse } from "@/models/remote/GetPlacesResponse";
import type { ValidateRailcardResponse } from "@/models/remote/ValidateRailcardResponse";
import type { JourneyFaresResponse } from "@/models/basket/JourneyFaresResponse";
import type { JourneySelectorViewModel } from "@/models/JourneySelectorViewModel";

export class RetailService {
    retailApiClient: IRetailApiClient;

    constructor(retailApiClient: IRetailApiClient) {
        this.retailApiClient = retailApiClient;
    }

    async getRailcards(): Promise<Array<RailCardItem>> {
        return this.retailApiClient.getRailcards().then((data) => data.railcards);
    }

    async validateRailCard(code: string, adults: number, children: number): Promise<ValidateRailcardResponse> {
        return this.retailApiClient.validateRailcard(code, adults, children);
    }

    // signal comes from AbortController
    async getPlaces(query: string, signal: AbortSignal): Promise<GetPlacesResponse> {
        return this.retailApiClient.getPlaces(query, signal);
    }

    async getPlace(id: string): Promise<SimplePlaceModel | undefined> {
        return this.retailApiClient.getPlace(id);
    }

    async getJourneys(count: number, request: GetJourneysRequest): Promise<JourneySelectorViewModel> {
        return this.retailApiClient.getJourneys(count, request);
    }

    async getJourneyFare(query: string): Promise<JourneyFaresResponse> {
        return this.retailApiClient.getJourneyFare(query);
    }
}

const retailApiClient = new RetailApiClient(new ApiClient());
export const retailService = new RetailService(retailApiClient);
