<!-- Shows an overview of a journey itinerary, including the date, duration, changes, and a list of legs.
@emit close - Emits when a close button is clicked. -->
<script setup lang="ts">
import { type Ref, watch } from "vue";
import { ref, toRefs, onMounted } from "vue";
import type { LegModel } from "@/models/basket/LegModel";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { getDateDiff, toLongDate } from "@/utilities/DateFunctions";
import { TransportModes } from "@/models/basket/TransportModes";
import { JourneyViewType } from "@/models/basket/JourneyViewType";
import type { GraphQLJourneyModel } from "@/models/JourneySelectorViewModel";
import type { IGQLLeg } from "@/models/graphql/BookingOverviewResponse";
import JourneySummaryTimeline from "./JourneySummaryTimeline.vue";
import { useJourneyStore } from "@/stores/journey-store";
const journeyStore = useJourneyStore();

const props = defineProps<{
    //inboundJourney: GraphQLJourneyModel | null,
    //outboundJourney: GraphQLJourneyModel | null,
    journeyKey: String,
    //journeyType: JourneyViewType,
    firstClassFare?: boolean,
}>();

const containsBusReplacement: Ref<boolean> = ref(false);
const selectedJourney: any = ref(null);
//const { inboundJourney } = toRefs(props);
//const { outboundJourney } = toRefs(props);
const isLegCostNotIncluded = (leg: IGQLLeg): boolean => leg.mode === TransportModes.Transfer;

function getConnectionTime(leg: IGQLLeg, index: number) {
    const numberOfLegs = selectedJourney.value.legs.length;
    const nextLeg = index + 1;
    if (numberOfLegs > 1 && numberOfLegs > nextLeg) {
        return getDateDiff(selectedJourney.value.legs[index].arriving, selectedJourney.value.legs[nextLeg].departing);
    }
    return null;
}

function calculateSelectedJourney() {
    //if (props.journeyType === JourneyViewType.Inbound) {
    //    selectedJourney.value = inboundJourney.value;
    //} else {
    //    selectedJourney.value = outboundJourney.value;
    //}
    if (selectedJourney.value) {
        containsBusReplacement.value = selectedJourney.value.legs.filter((v: IGQLLeg) => v.mode === TransportModes.Train).length > 0 && selectedJourney.value.legs.filter((v: LegModel) => v.mode === TransportModes.Bus).length > 0;
    }
}

onMounted(() => {
    selectedJourney.value = journeyStore.getJourneyByJourneyKey(props.journeyKey);
    calculateSelectedJourney();
})

//watch([outboundJourney, inboundJourney, journeyType], () => {
  //  calculateSelectedJourney();
//});
</script>

<template>
    <div class="text-center">
        <v-dialog :model-value="true" persistent scrollable width="auto">
            <v-card>
                <v-card-title class="text-center">
                    Journey Summary
                    <a class="float-end" @click="$emit('close')">
                        <font-awesome-icon icon="fa-5 fa-circle-xmark"></font-awesome-icon>
                    </a>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="selectedJourney">
                    <v-sheet
                        id="journey-summary-header"
                        class="d-flex align-center justify-center flex-wrap text-start text-lg-center mx-auto mb-4 pa-2"
                        border
                        rounded>
                        <v-row no-gutters>
                            <v-col cols="12" sm="4">
                                <div class="d-inline-block" style="width: 15px">
                                    <font-awesome-icon :icon="['fad', 'calendar']"></font-awesome-icon>
                                </div><br>
                                <div id="journey-summary-header-date" class="d-inline-block ps-3">
                                    {{ toLongDate(selectedJourney.departing) }}
                                </div>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <div class="d-inline-block" style="width: 15px">
                                    <font-awesome-icon :icon="['fad', 'clock']"></font-awesome-icon>
                                </div><br>
                                <div id="journey-summary-header-duration" class="d-inline-block ps-3">
                                    Duration: {{ getDateDiff(selectedJourney.departing, selectedJourney.arriving) }}
                                </div>
                            </v-col>
                            <v-col cols="12" sm="4" v-if="selectedJourney.changes > 0">
                                <div class="d-inline-block" style="width: 15px">
                                    <font-awesome-icon :icon="['fad', 'arrow-right-arrow-left']"></font-awesome-icon>
                                </div><br>
                                <div id="journey-summary-header-changes" class="d-inline-block ps-3">
                                    Changes: {{ selectedJourney.changes }}
                                </div>
                            </v-col>
                        </v-row>
                    </v-sheet>

                    <v-row v-if="containsBusReplacement">
                        <v-col>
                            <v-alert
                                id="journey-summary-bus-replacement-header"
                                border="start"
                                density="compact"
                                variant="flat"
                                color="var(--vt-c-warning)"
                                text="Rail replacement bus service is included in this journey">
                            </v-alert>
                        </v-col>
                    </v-row>

                    <div class="mb-2" v-for="(leg, legIndex) in selectedJourney.legs" :key="legIndex">
                        <v-row v-if="!leg.serviceDetails?.operator.firstClass && firstClassFare">
                            <v-col>
                                <v-alert
                                    id="journey-summary-bus-replacement-header"
                                    border="start"
                                    density="compact"
                                    variant="flat"
                                    color="var(--vt-c-warning)"
                                    text="This leg of the journey does not have first class accomodation. Your tickets will still be valid.">
                                </v-alert>
                            </v-col>
                        </v-row>
                        <v-alert
                            :id="`leg-${legIndex}-cost-not-included-item`"
                            v-if='isLegCostNotIncluded(leg)'
                            class="mt-2 mb-2 text-center"
                            density="compact"
                            color="var(--vt-c-primary)">
                            Please note: The cost is <span class="font-weight-bold">not included</span> in fare
                        </v-alert>
                        <v-row>
                            <v-col>
                                <JourneySummaryTimeline
                                    :leg="leg"
                                    :leg-index="legIndex"
                                    :places="leg.stops"
                                    :contains-bus-replacement="false">
                                </JourneySummaryTimeline>
                            </v-col>

                        </v-row>
                        <v-row
                            :id="`leg-${legIndex}-connection-time`"
                            v-if='getConnectionTime(leg, legIndex) !== null'>
                            <v-col>
                                <v-alert
                                    :class="`text-center wait-time-${legIndex}}`"
                                    density="compact"
                                    color="var(--vt-c-green)">
                                    Connection time: {{ getConnectionTime(leg, legIndex) }}
                                </v-alert>
                            </v-col>
                        </v-row>
                    </div>

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn variant="text" class="primary-button" @click="$emit('close')">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped lang="scss">
.v-card {
    width: 800px;
    .v-card-text {
        height: fit-content;
    }

    .v-divider {
        margin: 0;
    }
}

.v-card-actions {
    button {
        background-color: var(--vt-c-orange);
        color: #fff;
        text-transform: capitalize;
    }
}
.v-col-sm-4.v-col-12 {
    text-align: center;
}
:deep(#journey-summary-header div) {
    font-weight: 600;
    font-size: 1rem;
}
@media only screen and (max-width: 760px) {
    .v-row.v-row--no-gutters {
        text-align: center;
        flex-direction: column;
        align-items: center;
    }
    .v-overlay__content .v-card .v-card-text {
        height: fit-content;
    }
}
</style>
