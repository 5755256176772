<!-- @emit close - Emits when a close button is clicked and this component should be closed -->
<script setup lang="ts">
import { ref, toRefs } from "vue";
import type { Ref } from "vue";
import type { IPriceBreakdown } from "@/models/graphql/PriceBreakdownResponse";
import type { IGQLTripsOverview } from "@/models/graphql/BookingOverviewResponse";
import { useDateFormat } from "@vueuse/core";
import { useBookingData } from "@/stores/booking-storage";

const bookingStore = useBookingData();
const props = withDefaults(defineProps<{
    priceBreakdown: IPriceBreakdown | null,
}>(), {
    priceBreakdown: null,
});

const { priceBreakdown } = toRefs(props);
const dialog: Ref<boolean> = ref(false);
const existingTrips: Ref<any> = ref(null);

function formatDate(date: string | undefined) {
    return useDateFormat(date, "DD MMMM YYYY").value;
}
function formatTrip(trip: IGQLTripsOverview) {
    let tripType: string = "";
    let joruenySummary: string = "";
    let dateSummary: string = "";
    if (trip.inboundJourney === null) {
        tripType = "Single";
        joruenySummary = `from ${trip.outboundJourney.originName} to ${trip.outboundJourney.destinationName}`;
        dateSummary = ` (out ${formatDate(trip.outboundJourney.legs[0].departing)})`;
    } else {
        tripType = "Return";
        joruenySummary = `from ${trip.outboundJourney.originName} to ${trip.outboundJourney.destinationName}`;
        dateSummary = ` (out ${formatDate(trip.outboundJourney.legs[0].departing)} - return ${formatDate(trip.inboundJourney.legs[0].departing)}`;
    }
    return `${tripType} journey ${joruenySummary} ${dateSummary}`;
}

function showDialog() {
    dialog.value = true;
    existingTrips.value = bookingStore.bookedTrips();
}
</script>

<template>
    <div class="text-center">
        <v-dialog :model-value="true" :persistent="true" :scrollable="true" width="800px">
            <v-card>
                <v-card-title class="text-center">
                    Price Breakdown
                    <a class="float-end" @click="$emit('close')">
                        <font-awesome-icon icon="fa-5 fa-circle-xmark"></font-awesome-icon>
                    </a>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    Our fee structure is simple, if your basket has a return journey in it, we charge you no more than £1.50.
                    Otherwise, the fee is £0.75. This means that after your first single/return journey is accounted for, subsequent journeys in the same transaction are <b>free</b> to book (you can add as many as you want, and won't pay any additional booking fees).

                    <div class="mt-3 text-subtitle-2 font-weight-bold">Selected fare for this journey</div>
                    {{ priceBreakdown!.tripAmountFormatted! }}
                    <div v-if="existingTrips !== null">
                        <div class="mt-3 text-subtitle-2 font-weight-bold">Existing basket items ({{ existingTrips.length }} Journey)</div>
                        <div v-for="(trip, i) in existingTrips" :key="i">
                            {{ formatTrip(trip) }}
                        </div>
                    </div>
                    <div class="mt-3 text-subtitle-2 font-weight-bold">Booking fee for basket</div>
                    {{ priceBreakdown!.bookingFeeFormatted! }}
                    <div class="mt-3 text-subtitle-2 font-weight-bold">Total to pay if you continue</div>
                    {{ priceBreakdown!.totalPaymentAmountFormatted! }}
                    <div class="mt-3">We never charge any card fees.</div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn class="primary-button" variant="text" @click="$emit('close')">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped lang="scss">

</style>
